import React, { useRef, useEffect } from 'react';

import './allmodals.css'

function ModalInformation({ children, onClose, typeOfModal }) {

    const btnRef = useRef();

    useEffect(() => {

        btnRef.current.focus();

    }, [])


    return (
        <div className='modaloverlay'>
            <div className='modaldisplay'>
                <header className="flex ml-5">
                    {typeOfModal === 'Success' ? <img width='60px' src={process.env.PUBLIC_URL + "/images/icons/success.png"} alt="none" />
                        : typeOfModal === 'Error' ? <img width='60px' src={process.env.PUBLIC_URL + "/images/icons/error.png"} alt="none" />
                            : <img width='60px' src={process.env.PUBLIC_URL + "/images/icons/info.png"} alt="none" />
                    }
                    <span style={{ marginLeft: '20px', fontSize: '32px', fontWeight: 'bold' }}>
                        {typeOfModal}
                    </span>
                </header>
                <hr></hr>
                <div className="row mb-4">
                    {children}
                </div>
                <div className="row">
                    <hr></hr>
                    <div className="text-center">
                        <button className='btn btn-info buttonWidth100' ref={btnRef} onClick={onClose} >Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalInformation;