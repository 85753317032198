import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className="loading-spinner">
            <img src="/images/icons/loading-gif.gif" alt="Loading..." />
        </div>
    );
};

export default LoadingSpinner;