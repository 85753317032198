import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GlobalLayout from "../../utils/hoc/globalLayout";
import { auth, db } from "../../../firebase.js";
import { query, collection, onSnapshot } from "firebase/firestore";
import { format } from "date-fns";

const MeetHistory = () => {
    const [meetings, setMeetings] = useState([]);
    const [docIDs, setDocIDs] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMeeting, setSelectedMeeting] = useState();

    const currentUser = auth.currentUser;
    const user = currentUser.displayName;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(
                    collection(db, "Meetings", format(selectedDate, "dd-MM-yyyy"), "Meet")
                );
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const fetchedMeetings = [];
                    const fetchedDocIDs = [];

                    querySnapshot.forEach((doc) => {
                        fetchedMeetings.push({ ...doc.data(), id: doc.id });
                        fetchedDocIDs.push(doc.id);
                    });

                    //***** Filter meetings based on tasks actionBy property


                    const matchingMeeting = fetchedMeetings.filter(
                        (meet) => meet.attendees.includes(user) || meet.chaired === user || meet?.nonAttendees?.includes(user)
                    );



                    setMeetings(matchingMeeting);
                    setDocIDs(fetchedDocIDs);
                });

                return () => {
                    // Cleanup the subscription when the component unmounts
                    unsubscribe();
                };
            } catch (error) {
                console.error("Error fetching Meeting:", error);
            }
        };

        fetchData();
    }, [selectedDate, user]);



    const handleTodayClick = () => {
        setSelectedDate(new Date());
        updateSelectedMeeting(new Date());
    };

    const handleTabClick = (date) => {
        setSelectedDate(date);
        updateSelectedMeeting(date);
    };



    const updateSelectedMeeting = useCallback((date) => {
        const dateObject = meetings.find((meet) => {
            const [formattedDate, formattedTime] = meet.meetDateTime.split(",");

            const anotherDate = format(date, "dd-MM-yyyy");

            return formattedDate === anotherDate;
        });
    }, [meetings]);

    useEffect(() => {
        // Load details of the first meeting when the component mounts
        updateSelectedMeeting(selectedDate);
    }, [selectedDate, updateSelectedMeeting]); // Empty dependency array ensures it runs only once on mount


    const changeSelectedMeeting = (meet) => {

        setSelectedMeeting(meet);
    };

    return (
        <GlobalLayout>
            <div className="flex flex-col md:flex-row lg:flex-row mt-8">
                <div className="overflow-y-auto">
                    {/* ************************ calender *********************************** */}
                    <div className="flex">
                        <DatePicker
                            className="border-1 border-gray-700 w-2/3 h-fit"
                            showIcon
                            selected={selectedDate}
                            onChange={(date) => handleTabClick(date)}
                            dateFormat="dd-MM-yyyy"
                        />

                        <button
                            className="bg-blue-500 h-fit px-2 py-1 text-white rounded"
                            onClick={handleTodayClick}
                        >
                            Today
                        </button>
                    </div>
                    {/* ************************ Sidebar *********************************** */}
                    <div className="md:flex-none lg:flex-none flex-row md:flex-col lg:flex-col">
                        {meetings.map((meet) => (

                            <div className="space-y-2 mt-4  max-h-[calc(100vh)]">
                                <div className="bg-slate-50 mb-3 p-1">
                                    <div
                                        className={`w-full text-left px-4 py-2 ${selectedDate === meet.meetDateTime ? "bg-gray-100" : ""
                                            }`}
                                    >
                                        {meet.meetDateTime}
                                    </div>
                                    {/* Meeting card */}
                                    <div key={meet.meetCode} className="flex flex-col mt-3">
                                        <div
                                            className={`text-lg font-semibold p-3 text-center cursor-pointer border-1 my-2 ${selectedMeeting === meet
                                                ? "bg-[#f1f5f9] shadow-sm border-blue-600"
                                                : ""
                                                }`}
                                            onClick={() => changeSelectedMeeting(meet)}
                                        >
                                            <p className="text-left text-gray-800">{meet.meetName}</p>
                                            <p className="text-xs text-right mt-2 text-gray-600">
                                                Duration:{meet.duration}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>

                </div>
                {/* ************************ DISPLAY *********************************** */}
                {meetings.length === 0 ? (
                    <div className="flex justify-center items-center h-[100%] ">
                        <img
                            className="opacity-95 mt-5 w-[100%] md:w-[70%] lg:w-[70%]"
                            src="/images/meetings.gif"
                            alt=""
                        />
                    </div>
                ) : (
                    selectedMeeting && (
                        <div className="w-full md:w-3/4 lg:w-3/4 p-4 m-0 md:m-2 lg:m-2 shadow-sm border bg-slate-50 border-blue-600">
                            <div className="flex justify-between">
                                <p>
                                    <span className="font-semibold uppercase"> Date:</span>{" "}
                                    {format(selectedDate, "dd-MM-yyyy")}
                                </p>
                                <p>
                                    <span className="font-semibold uppercase">Duration :</span>{" "}
                                    {selectedMeeting.duration}
                                </p>


                                <p>
                                    <span className="font-semibold uppercase">Meeting Code: </span>
                                    {selectedMeeting.meetCode}
                                </p>
                                <p>
                                    <span className="font-semibold uppercase">
                                        Meeting held at:{" "}
                                    </span>{" "}
                                    {selectedMeeting.location}
                                </p>
                            </div>
                            <div className="flex flex-col md:flex-row lg:flex-row justify-between">
                                <h2 className="text-2xl text-blue-950 font-bold my-4">
                                    {selectedMeeting && `Meeting Title : ${selectedMeeting.meetName}`}{" "}
                                </h2>
                                <div className="chairing flex h-fit items-center justify-end my-2 w-fit">
                                    <p className="font-semibold uppercase ">
                                        Chaired By:

                                    </p>

                                    <p className="m-1 ">
                                        {selectedMeeting.chaired}
                                    </p>

                                </div>
                            </div>

                            <div className="flex justify-between">

                                <div className="attendees">
                                    <p className="font-semibold uppercase">Attendees:</p>
                                    <div className="flex flex-wrap">
                                        {selectedMeeting.attendees.map((attend, index) => (
                                            <span
                                                className="m-1"
                                                key={index}
                                            >
                                                {index + 1}. {attend}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="nonattendees">
                                    <p className="font-semibold uppercase">Non Attendees:</p>
                                    <div className="flex flex-wrap">
                                        {selectedMeeting?.nonAttendees?.map((attend, index) => (
                                            <span
                                                className="m-1"
                                                key={index}
                                            >
                                                {index + 1}. {attend}
                                            </span>
                                        ))}
                                    </div>
                                </div>

                            </div>
                            <div className="meetNote">
                                <p style={{ whiteSpace: "pre-line" }} ><span className="font-bold"> Meeting Note :</span> <br />  <br /> {selectedMeeting.meetNote}</p>
                            </div>
                            {/* **************** TASKS ****************** */}
                            {selectedMeeting &&
                                selectedMeeting.tasks.map((task, index) => (
                                    <>
                                        <section key={task.no} className="w-full mt-4">
                                            <details className="w-full bg-white border-1 border-opacity-5 rounded-xl shadow-md group mx-auto overflow-hidden max-h-full open:!max-h-[1000px] transition-[max-height] duration-500">
                                                <summary className="container items-center bg-gray-50 text-lg px-2 py-2 outline-none cursor-pointer focus:text-indigo-800 font-semibold marker:text-transparent group-open:before:rotate-90  before:origin-center relative before:w-[18px] before:h-[18px] before:transition-transform before:duration-200 before:left-3 before:top-2/4 before:-translate-y-2/4 before:absolute before:bg-no-repeat before:bg-[length:18px_18px] before:bg-center before:bg-[url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22h-6%20w-6%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%3E%0A%20%20%3Cpath%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20d%3D%22M9%205l7%207-7%207%22%20%2F%3E%0A%3C%2Fsvg%3E')]">
                                                    <div className="row border-b-1">
                                                        <div className="col-sm-2">
                                                            <p className="ml-[2rem] w-fit text-base">No: {task.no}</p>{" "}
                                                        </div>
                                                        <div className="col-sm-10">
                                                            {" "}
                                                            <p className="ml-auto mr-auto text-lg font-normal px-2">
                                                                <span className="font-bold"> Task Name: </span>{task.agenda}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <p className="font-normal">Action For: </p>
                                                            <span className="text-base font-bold p-2 m-1 text-pink-600">
                                                                {task.actionFor}
                                                            </span>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p>Action By: </p>

                                                            <span className="text-base font-bold p-2 m-1 text-pink-600">
                                                                {task.actionBy}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </summary>

                                                <hr className="" />

                                                <div className="p-3">
                                                    <div className="flex justify-between">
                                                        <p className="start">
                                                            <span className="font-semibold uppercase">
                                                                Start Date:{" "}
                                                            </span>
                                                            {task.startDate}
                                                        </p>
                                                        <p className="start">
                                                            <span className="font-semibold uppercase">
                                                                Target Date:{" "}
                                                            </span>
                                                            {task.targetDate}
                                                        </p>
                                                    </div>

                                                    <div className="Description my-2">
                                                        <p className="font-semibold flex uppercase">
                                                            Description:
                                                        </p>
                                                        <p style={{ whiteSpace: "pre-line" }} >{task.discussionPoints}</p>
                                                    </div>
                                                    <p>Status: {task.status}</p>
                                                    <p className="font-semibold mt-4 mb-1 uppercase">
                                                        Subtask:
                                                    </p>

                                                    <table
                                                        key={task.no}
                                                        className="table-auto border-collapse border border-blue-900"
                                                    >
                                                        <thead>
                                                            {task.subtask1.subtaskName.length > 0 && (
                                                                <tr className="bg-blue-100">
                                                                    <th
                                                                        style={{ width: "40%" }}
                                                                        className="border border-blue-500 px-4 py-2"
                                                                    >
                                                                        Subtask Name
                                                                    </th>
                                                                    <th
                                                                        style={{ width: "35%" }}
                                                                        className="border border-blue-500 px-4 py-2"
                                                                    >
                                                                        Supported By
                                                                    </th>
                                                                    <th
                                                                        style={{ width: "45%" }}
                                                                        className="border border-blue-500 px-4 py-2"
                                                                    >
                                                                        Target Date
                                                                    </th>
                                                                    <th
                                                                        style={{ width: "45%" }}
                                                                        className="border border-blue-500 px-4 py-2"
                                                                    >
                                                                        Status
                                                                    </th>
                                                                </tr>
                                                            )}
                                                        </thead>
                                                        <tbody>
                                                            {task.subtask1.subtaskName.length > 0 && (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask1.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask1.supportedBy.label}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            task.subtask1.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask1?.status}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {task.subtask2.subtaskName.length > 0 && (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask2.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask2.supportedBy.label}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            task.subtask2.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask2?.status}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {task.subtask3.subtaskName.length > 0 && (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask3.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask3.supportedBy.label}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            task.subtask3.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask3?.status}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {task.subtask4.subtaskName.length > 0 && (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask4.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask4.supportedBy.label}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            task.subtask4.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {task.subtask4?.status}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </details>

                                        </section>
                                    </>
                                ))}
                        </div>
                    )
                )}
            </div>
        </GlobalLayout>
    );
};

export default MeetHistory;
