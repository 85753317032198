import React from "react";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";

const AgendaTable = ({ editCallback, newData, deleteCallback }) => {
    const options = {
        filterType: "checkbox",
        tableBodyHeight: "400px",
        pagination: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        download: false,
        print: false,
        search: false,
        filter: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: "",
                toolTip: "",
            },
        },
    };

    const columns = [
        {
            name: "no",
            label: "No",
            options: {
                filter: true,
                sort: false,
                // width: "50px",
            },
        },
        {
            name: "agenda",
            label: "Agenda",
            options: {
                filter: true,
                sort: false,
                // width: "200px",
            },
        },
        {
            name: "discussionPoints",
            label: "Discussion Points",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "department",
            label: "Department",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "actionBy",
            label: "Action By",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "actionFor",
            label: "Action For",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "startDate",
            label: "Start Date",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "startDateISO",
            label: "Start Date ISO",
            options: {
                filter: true,
                display: false,
                sort: false,
            },
        },
        {
            name: "targetDate",
            label: "Target Date",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "targetDateISO",
            label: "Target Date ISO",
            options: {
                filter: true,
                display: false,
                sort: false,
            },
        },
        {
            name: "subtask1",
            label: "Subtask 1",
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        },
        {
            name: "s1TargetDate",
            label: "s1 Target Date",
            options: {
                filter: true,
                display: false,
                sort: false,
            },
        },
        {
            name: "subtask2",
            label: "Subtask 2",
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        },

        {
            name: "subtask3",
            label: "Subtask 3",
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        },

        {
            name: "subtask4",
            label: "Subtask 4",
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        },

        //https://stackoverflow.com/questions/55102156/how-to-give-action-buttons-in-muidatatable
        // Adding a button / Icon for every row
        {
            name: "Action",
            label: "Action",

            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (

                        <div div className="row" >
                            <div className="col-sm-4">
                                <button onClick={() => handleEditRow(tableMeta.rowIndex)}>
                                    <EditIcon
                                        className="text-success text-3xl"
                                    />
                                </button>
                            </div>
                            <div className="col-sm-4">
                                <button
                                    onClick={() => handleDeleteRow(tableMeta.rowIndex)}>
                                    <DeleteIcon
                                        className="text-danger  text-3xl"

                                    />
                                </button>
                            </div>
                        </div>
                    );
                },
            },
        },
    ];
    const handleEditRow = (editRowIndex) => {
        editCallback(editRowIndex);
    };

    const handleDeleteRow = (deleteRowIndex) => {
        deleteCallback(deleteRowIndex);
    };

    const DataTableContainer = styled("div")(() => ({
        "& .MuiTableCell-root": {
            fontSize: "12px",

            "&:nth-of-type(2)": {
                // width: "250px",
                paddingLeft: "1rem",
            },
            "&:nth-of-type(3)": {
                // width: "250px",
                paddingLeft: "1rem",
            },
        },
        "& .MuiTableCell-head": {
            fontSize: "13px",
            fontWeight: "bold",
            background: "rgb(226 232 240);",
            borderRight: "1px solid gray",
            "&:nth-of-type(1)": {
                borderLeft: "1px solid gray",
            },
        },
        "& .MuiToolbar-root": {
            borderBottom: "1px solid gray",
        },
        "& .MuiButtonBase-root": {
            fontSize: "16px",
            fontWeight: "bold",
            color: "darkGreen",
        },

        "& .tss-1cdcmys-MUIDataTable-responsiveBase": {
            height: "fit-content !important",
        },
    }));

    return (
        <DataTableContainer className="mt-4">
            <MUIDataTable
                title={"Agenda List"}
                data={newData}
                columns={columns}
                options={options}
            />
        </DataTableContainer>
    );
};

export default AgendaTable;
