import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { auth } from "../../../firebase";
import { updatePassword } from "firebase/auth";
import { useAlert } from "react-alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({

    newPass: yup
        .string()
        .required("New Password is required")
        .min(6, "New Password must be at least 6 characters"),
    confirmPass: yup
        .string()
        .oneOf(
            [yup.ref("newPass"), null],
            "Confirm Password must match the password"
        ).required("New Password is required"),

});

function ForgotPass() {
    const user = auth.currentUser;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const alert = useAlert();

    const changePassword = (data) => {
        if (data.newPass === data.confirmPass) {
            updatePassword(user, data.newPass)
                .then(() => {
                    alert.success("Password Changed successfully");
                })
                .catch((error) => {
                    alert.error("Failed to Update.Please login again", error);
                });
        } else {
            alert.error("Password does not matched");
        }
    };
    return (
        <form className="w-full px-4" onSubmit={handleSubmit(changePassword)}>
            {/* <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4"
                htmlFor="curr_pass"
            >
                Current Password
            </label> */}
            {/* <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border border-red-500 rounded py-2 px-2 mb-4 leading-tight focus:outline-none focus:bg-white"
                id="curr_pass"
                // name="currPass"
                type="password"
                placeholder="****"
                {...register("currPass", {
                    required: "Current Password is Required",
                    minLength: {
                        value: 4,
                        message: "Minimum 4 characters required.",
                    },
                })}
            /> */}
            {/* <p className="text-red-500 font-semibold mb-2 ms-[20%]">
                {errors.currPass?.message && (
                    <span>
                        <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                        {errors.currPass?.message}
                    </span>
                )}
            </p> */}
            <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="new_pass"
            >
                New Password
            </label>
            <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border border-red-500 rounded py-2 px-2 mb-4 leading-tight focus:outline-none focus:bg-white"
                id="new_pass"
                // name="newPass"
                type="password"
                placeholder="****"
                {...register("newPass")}
            />
            <p className="text-red-500 font-semibold mb-2 ms-[20%]">
                {errors.newPass?.message && (
                    <span>
                        <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                        {errors.newPass?.message}
                    </span>
                )}
            </p>
            <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="confirm_pass"
            >
                Confirm Password
            </label>
            <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border border-red-500 rounded py-2 px-2 mb-4 leading-tight focus:outline-none focus:bg-white"
                id="confirm_pass"
                name="confirmPass"
                type="password"
                placeholder="****"
                {...register("confirmPass")}
            />
            <p className="text-red-500 font-semibold mb-2 ms-[20%]">
                {errors.confirmPass?.message && (
                    <span>
                        <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                        {errors.confirmPass?.message}
                    </span>
                )}

            </p>
            <div className="flex justify-center">
                <button
                    type="submit"
                    className="py-2 px-3 rounded bg-[#252c48] font-medium text-white hover:bg-[#3a4675]"
                >
                    Change Password
                </button>
            </div>
        </form>
    );
}

export default ForgotPass;
