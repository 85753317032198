import React, { useState, useEffect } from "react";
import GlobalLayout from "../../utils/hoc/globalLayout";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { fetchMeeting } from "../../utils/utilities/fetchMeeting";
import { isBefore, isAfter } from "date-fns";
import { convertDateFormat } from "../../utils/utilities/utils.js";

const Task = () => {
  const [allTasks, setAllTasks] = useState();
  var counterOpen = 0;
  var counterOverdue = 0;
  var counterCompleted = 0;
  const firebaseTimestamp = firebase.firestore.Timestamp.now().toDate();

  // Manipulating the timestamp
  const timestamp = new Date(firebaseTimestamp);
  timestamp.setHours(0);
  timestamp.setMinutes(0);
  timestamp.setSeconds(0);
  timestamp.setMilliseconds(0);

  useEffect(() => {
    (async () => {
      setAllTasks(await fetchMeeting());
    })();
  }, []);

  if (allTasks) {
    allTasks.forEach((task) => {
      const newTargetDate = new Date(convertDateFormat(task.targetDate));

      if (task.status === "Ongoing" && newTargetDate >= timestamp) {
        counterOpen = counterOpen + 1;
      }
    });
    allTasks.forEach((task) => {
      const newTargetDate = new Date(convertDateFormat(task.targetDate));
      if (task.status === "Ongoing" && newTargetDate < timestamp) {


        counterOverdue = counterOverdue + 1;
      }
    });
    allTasks.forEach((task) => {
      if (task.status === "Completed") {
        counterCompleted = counterCompleted + 1;
      }
    });
  }

  return (
    <GlobalLayout>
      <div className="flex justify-right task-header mt-4">
        <p className="text-2xl font-bold mr-auto">My Task</p>
      </div>

      <main className="TaskMain mt-4 container">
        <div className="row gx-3 gy-5">
          <div className="col-sm-4">

            <Link
              to="/taskdetails"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                borderRadius: "10px",
                // width: "30%",
              }}
              className="onGoing hover:-translate-y-1 transition ease-in-out "
            >
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
                className="title w-11/12  rounded text-black bg-amber-500 h-[100%] p-10"
              >
                <span className="text-xl ">Ongoing & Upcoming : &nbsp; &nbsp;</span>
                <span className="text-xl ">{counterOpen}</span>
              </div>
            </Link>


          </div>
          <div className="col-sm-4">
            {" "}
            <Link
              to="/overdueTasks"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                borderRadius: "10px",
                // width: "30%",
              }}
              className="overDue hover:-translate-y-1 transition ease-in-out "
            >
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
                className="title w-11/12 rounded text-white  bg-red-700 h-[100%] p-10"
              >
                <span className="text-xl">Overdue : &nbsp; &nbsp;</span>
                <span className="text-xl">{counterOverdue}</span>
              </div>
            </Link>
          </div>
          <div className="col-sm-4">
            <Link
              to="/completedTasks"
              style={{
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                borderRadius: "10px",
                // width: "30%",
              }}
              className="Completed hover:-translate-y-1 transition ease-in-out "
            >
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
                className="title w-11/12 rounded text-black bg-lime-500 h-[100%] p-10"
              >
                <span className="text-xl">Completed : &nbsp; &nbsp;</span>
                <span className="text-xl">{counterCompleted}</span>
              </div>
            </Link>

          </div>
        </div>
      </main>

    </GlobalLayout >
  );
};

export default Task;
