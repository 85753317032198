import React from "react";
import GlobalLayout from "../../utils/hoc/globalLayout";
import { useEffect, useState } from "react";
import "./NewMeetMins.css";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { db } from "../../../firebase.js";
import HtmlDropdown from "../../utils/elements/HtmlSelect/index.js";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import ModalAddAgenda from "../../utils/elements/modals/ModalAddAgenda.js";
import AgendaTable from "../../utils/elements/Tables/AgendaTable.js";
import { useAlert } from "react-alert";
import InputField from "../../utils/elements/InputField/index.js";
import ModalConfirm from "../../utils/elements/modals/ModalConfirm.js";

const MeetLocation = [
  { value: 0, label: "Select Location" },
  { value: 1, label: "Office" },
  { value: 2, label: "Online" },
  { value: 3, label: "On Site" },
  { value: 4, label: "Client" },
];

function NewMeetMins() {
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [meetCode, setMeetCode] = useState("");
  const [meetName, setMeetName] = useState("");
  const [meetNote, setMeetNote] = useState("");

  const [meetLocation, setMeetLocation] = useState({
    value: 0,
    label: "Select Location",
  });
  const [userList, setUserList] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [nonAttendees, setNonAttendees] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalState2, setModalState2] = useState(false);
  const [chaired, setChaired] = useState({
    value: 0,
    label: "Select Conducted By",
  });
  const [chairmanData, setChairmanData] = useState([]);

  const [agendaItems, setAgendaItems] = useState([]);
  const [addEdit, setAddEdit] = useState(-1);

  const [meetingDocID, setMeetingDocID] = useState("");

  // state to store time
  const [time, setTime] = useState(0);
  // Hours calculation
  const hours = Math.floor(time / 360000);
  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);
  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);
  const [error, setError] = useState({});
  const alert = useAlert();

  const handleDropdownLocation = (data) => {
    setMeetLocation(data); // set the data to a state from child
  };

  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(format(new Date(), "dd-MM-yyyy,hh:mma"));
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 5);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Method to start and stop timer
  const startAndStop = () => {
    setIsRunning(!isRunning);
  };

  useEffect(() => {
    const getDate = () => {
      const timestamp = firebase.firestore.Timestamp.now().toDate();

      setMeetCode(format(timestamp, "yyMMddHHmmssms"));
    };
    getDate();
  }, []);

  useEffect(() => {
    // Fetch the list of users from Firestore
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, "Users");
        const snapshot = await getDocs(usersRef);
        const users = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserList(users);

        var counter = 0;
        setChairmanData(
          users.reduce(
            (acc, user) => {
              if (user.isManager) {
                acc.push({
                  value: counter + 1,
                  label: user.name,
                });
                counter = counter + 1;
              }
              return acc;
            },
            [
              {
                value: 0,
                label: "Select Conducted By",
              },
            ],
          ),
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => { }, []);

  // Chairing the meeting
  const handleChairmenSelect = (values) => {
    setChaired(values);
    // console.log("onSelect", values)
  };

  const handleAttendeesChange = (event, values) => {
    setAttendees(values);
  };
  const handleNonAttendeesChange = (event, values) => {
    setNonAttendees(values);
  };

  const showHideModalConfirm = () => {
    if (modalState) {
      setModalState(false);
      setAddEdit(-1);
    } else {
      setModalState(true);
    }
  };

  const saveData = () => {
    //for the Modal Confirm

    setModalState(false);
  };

  const handleSaveDataFromModal = (agendaData) => {
    // Handle the received data here, for example, you can store it in state

    if (addEdit === -1) {
      if (agendaItems) {
        setAgendaItems([...agendaItems, agendaData]);
      } else {
        agendaItems.push(agendaData);
      }
    } else {
      //Update the object with the Index which is added it

      agendaItems[addEdit].agenda = agendaData.agenda;
      agendaItems[addEdit].discussionPoints = agendaData.discussionPoints;
      agendaItems[addEdit].department = agendaData.department;
      agendaItems[addEdit].actionBy = agendaData.actionBy;
      agendaItems[addEdit].actionFor = agendaData.actionFor;
      agendaItems[addEdit].startDate = agendaData.startDate;
      agendaItems[addEdit].targetDate = agendaData.targetDate;
      agendaItems[addEdit].startDateISO = agendaData.startDateISO;
      agendaItems[addEdit].targetDateISO = agendaData.targetDateISO;
      agendaItems[addEdit].subtask1 = agendaData.subtask1;
      agendaItems[addEdit].subtask2 = agendaData.subtask2;
      agendaItems[addEdit].subtask3 = agendaData.subtask3;
      agendaItems[addEdit].subtask4 = agendaData.subtask4;
      agendaItems[addEdit].s1TargetDate = agendaData.s1TargetDate;
      agendaItems[addEdit].s2TargetDate = agendaData.s2TargetDate;
      agendaItems[addEdit].s3TargetDate = agendaData.s3TargetDate;
      agendaItems[addEdit].s4TargetDate = agendaData.s4TargetDate;
    }

    // Store the data in state or perform any other necessary actions
  };

  const editAgendaCallBack = (editRowIndex) => {
    setAddEdit(editRowIndex);
    showHideModalConfirm();
  };

  const deleteAgendaCallBack = (deleteRowIndex) => {
    const updatedAgendaItems = agendaItems.filter(
      (item, index) => index !== deleteRowIndex,
    );

    updatedAgendaItems.forEach((obj, index) => {
      obj.no = index + 1;
    });

    if (updatedAgendaItems.length > 0) {
      setAgendaItems(updatedAgendaItems);
    } else {
      setAgendaItems([]);
    }
  };

  const onChangeMeetNote = (data) => {
    // console.log("onChangeMeetNote--" + data);
    setMeetNote(data);
  };

  const validateValues = () => {
    let error = {};
    if (chaired.value === 0) {
      error.chaired = "Conducted by is required ";
    }
    if (meetName.length < 1) {
      error.meetName = "Meet Name is required ";
    }
    if (meetLocation.value === 0) {
      error.meetLocation = "Please Select Location";
    }
    if (attendees.length < 1) {
      error.attendees = "Attendees is required ";
    }

    return error;
  };
  // console.log("Err in Ch", error.chaired)
  // const onLoadMode = () => {
  //   setAttendees([]);
  //   setAgendaItems([]);
  //   setMeetCode("");
  //   setMeetName("");
  //   setMeetNote("");
  //   setMeetLocation({});
  //   setChaired("");
  //   setAddEdit(-1);
  //   setNonAttendees([]);
  //   getDate();
  // };
  const dummyData = { dummyFieldData: "dummyFieldData" };

  const showHideAlert = (state) => {
    setModalState2(() => false);
  };

  const saveMeeting = async () => {
    setModalState2(false);

    const formattedDate = format(new Date(), "dd-MM-yyyy");
    // let meetingData = {};
    if (
      meetName &&
      meetLocation.value > 0 &&
      attendees.length > 0 &&
      chaired.value > 0
    ) {
      setError({});

      agendaItems.forEach((obj, index) => {
        obj.subtask1 = JSON.parse(agendaItems[index].subtask1);
        obj.subtask2 = JSON.parse(agendaItems[index].subtask2);
        obj.subtask3 = JSON.parse(agendaItems[index].subtask3);
        obj.subtask4 = JSON.parse(agendaItems[index].subtask4);
      });
      agendaItems.forEach((item, index) => {
        const taskID = `TID-${meetCode}-${item.no}`;
        Object.assign(item, { taskID: taskID });
        Object.assign(item, { agendaMeetCode: meetCode });
      });
      const meetingData = {
        meetCode: meetCode,
        meetName: meetName,
        meetNote: meetNote,
        duration: `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`,
        location: meetLocation.label || " ",
        meetDateTime: currentDateTime.toLocaleString(),
        attendees: attendees,
        nonAttendees: nonAttendees,
        chaired: chaired.label,
        tasks: agendaItems,
      };

      if (meetingDocID === "") {
        // console.log(validateValues())
        // console.log("Came inside 1st if loop");
        await setDoc(
          doc(db, "Meetings", formattedDate.toString(), "Meet", meetCode),
          meetingData,
        );

        // await console.log(meetingData)
        await setDoc(doc(db, "Meetings", formattedDate.toString()), dummyData);
        setMeetingDocID(meetCode);
        // console.log("Meeting Adding:" + meetCode);
        // onLoadMode();
        alert.success("Meeting Saved Continue to Add - Edit if required !");
      } else {
        await setDoc(
          doc(db, "Meetings", formattedDate.toString(), "Meet", meetingDocID),
          meetingData,
        );
        // await console.log(meetingData)
        // await setDoc(doc(db, "Meetings", formattedDate.toString()), dummyData);
        setMeetingDocID(meetCode);
        // onLoadMode();
        alert.success("Meeting Saved successfully !");

      }
    } else {
      setError(validateValues());
    }
  };

  return (
    <GlobalLayout>
      <div className="TopFeatures mt-3 flex flex-col items-start md:flex-row md:items-center lg:flex-row lg:items-center ">
        <p className="text-lg font-bold lg:w-[19.5%]">Meeting Minutes</p>

        <div className="stopwatch-container lg:w-[23%] ml-0 flex items-center font-semibold md:ml-6 lg:ml-6">
          <p className="stopwatch-time">
            Active session Time :{hours}:{minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
          </p>
          <div className="stopwatch-buttons mx-2">
            <button
              className={`stopwatch-button rounded px-2 py-1 ${isRunning ? "bg-red-700" : "bg-green-700"
                } text-white`}
              onClick={startAndStop}
            >
              {isRunning ? "Stop" : "Start"}
            </button>
          </div>
        </div>
        <div className="relative ml-0 flex w-11/12 items-center p-2 md:ml-2 md:w-1/2 lg:ml-2 lg:w-[50%]">
          <p className=" w-2/3 font-semibold md:w-[18%] lg:w-[18%]">
            Conducted By :
          </p>
          <div className="w-1/2">
            <HtmlDropdown
              data={chairmanData}
              onChange={handleChairmenSelect}
              placeholder=""
              value={chaired.value}
              defaultValue={chaired.value}
            />

            {error.chaired ? (
              <p className="mt-2 w-full font-semibold text-red-500">
                <i className="bi bi-exclamation-circle mr-2"></i> {error.chaired}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <main className="MeetTable mr-6 mt-3 border-gray-900">
        <div className="TableTop mt-2 flex flex-wrap items-center justify-start  gap-4 border bg-slate-200  p-2 font-semibold">
          <p>Minutes Code: {meetCode}</p>
          <div className="w-full md:w-[23%] lg:w-[23%]">
            <TextField
              className="w-full bg-white"
              value={meetName || ""}
              id="outlined-basic"
              name="meetName"
              label="Meeting Name"
              variant="outlined"
              onChange={(e) => setMeetName(e.target.value)}
            />

            {error.meetName ? (
              <p className=" mt-1 font-semibold text-red-500">
                <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                {error.meetName}
              </p>
            ) : null}
          </div>

          <div className="meetLocation flex w-full items-center md:w-1/3 lg:w-1/3">
            <p className="w-[28%]">Location:</p>
            <div className="w-full">
              <HtmlDropdown
                data={MeetLocation}
                onChange={handleDropdownLocation}
                value={meetLocation.value}
                defaultValue={meetLocation.value}
                placeholder=""
              />
              {error.meetLocation ? (
                <div className="relative">
                  <p className="font-semibold text-red-500">
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {error.meetLocation}
                  </p>
                </div>
              ) : null}
            </div>
          </div>

          <p className="ml-0 w-full font-bold md:w-fit lg:w-fit">
            Date: {currentDateTime.toLocaleString()}
          </p>
        </div>
        <div className="attendees gy-3 mt-3">
          <div className="flex w-full gap-5">
            <div className="w-1/2">
              <Autocomplete

                multiple
                id="combo-box-demo"
                options={userList.map((user) => user.name)}
                onChange={handleAttendeesChange}
                value={attendees || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Attendee Name" />
                )}
              />
              {error.attendees ? (
                <p className=" bottom-0 font-semibold text-red-500">
                  <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                  {error.attendees}
                </p>
              ) : null}
            </div>

            <Autocomplete
              className="w-1/2 h-fit"
              multiple
              id="combo-box-demo3"
              options={userList
                .map((user) => user.name)
                .filter((name) => !attendees.includes(name))}
              onChange={handleNonAttendeesChange}
              value={nonAttendees || ""}
              renderInput={(params) => (
                <TextField {...params} label="Non-Attendees" />
              )}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            {/* <p className="my-2">Meeting Notes</p> */}

            <InputField
              type={"textarea"}
              id="outlined-multiline-static"
              onChange={onChangeMeetNote}
              value={meetNote || ""}
              rows={4}
              label={"Meeting Notes"}
              placeholder="Meeting Notes"
            />
          </div>
        </div>
        <div className="flex justify-between my-5">
          <div className="">
            <button onClick={showHideModalConfirm} className="btn btn-success">
              Add Task
            </button>
            {modalState && (
              <ModalAddAgenda
                addEdit={addEdit}
                userList={userList}
                onClose={() => showHideModalConfirm()}
                saveData={saveData}
                saveDataToParent={handleSaveDataFromModal}
                agendaItems={agendaItems}
              />
            )}
          </div>

          <div className="save-meet ">
            <button
              type="submit"
              onClick={() => saveMeeting()}
              className="rounded bg-[#252c48] px-3 py-2 text-sm font-medium text-white hover:bg-[#252c48ce]"
            >
              Save Meeting
            </button>
            {modalState2 && (
              <ModalConfirm
                onClose={() => showHideAlert(modalState2)}
                saveData={() => saveMeeting()}
              >
                <p>Are you confirm to save....!</p>
              </ModalConfirm>
            )}
          </div>
        </div>
        {agendaItems.length > 0 && (
          <>
            <AgendaTable
              editCallback={editAgendaCallBack}
              newData={agendaItems}
              deleteCallback={deleteAgendaCallBack}
            />
          </>
        )}
      </main>
    </GlobalLayout>
  );
}

export default NewMeetMins;
