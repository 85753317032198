import React from "react";
import { auth } from "../../firebase";
import Accordion from "../utils/elements/Accordion";
import TaskPieChart from "../utils/elements/Taskpiechart";
import GlobalLayout from "../utils/hoc/globalLayout";

const Home = () => {
    const user = auth.currentUser;

    return (
        <GlobalLayout>
            <div className="w-[375px] overflow-hidden md:w-full lg:w-full">
                <div className="upperFeature my-4 flex flex-col md:flex-row lg:flex-row">
                    <p className="font-bold ">
                        Welcome, <span className="text-blue-950">{user.displayName}</span>
                    </p>
                    {/* <div className="ml-2 md:ml-auto lg:ml-auto w-full md:w-1/2 lg:w-1/2">
                        <p>Progress : </p>
                        <div className="w-2/4 bg-gray-200 rounded-full h-fit">
                            <div
                                className="bg-[#252c48] text-sm font-medium text-blue-100 text-center p-1 leading-none rounded-full"
                                style={{ width: value }}
                            >
                                {" "}
                                {value}
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="row ml-2 shadow-lg mr-6">
                    <div
                        style={{ border: "1px solid", padding: "1.2%" }}
                        className="taskFeature flex "
                    >
                        <div className="col-sm-3"><p className="font-bold text-xl">Tasks</p></div>
                        <div className="col-sm-9 TaskList flex justify-start flex-wrap mx-auto">
                            <div className="flex items-center">
                                <div className="w-3 h-3 rounded bg-yellow-500 mx-2"></div>Ongoing
                            </div>
                            <div className="flex items-center">
                                <div className="w-3 h-3 rounded bg-red-600 mx-2"></div>Overdue
                            </div>
                            <div className="flex items-center">
                                <div className="w-3 h-3 rounded bg-green-500 mx-2"></div>{" "}
                                Completed
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ border: "1px solid", borderTop: "none", padding: "1.2%" }}
                        className="Charts "
                    >
                        <div className="row ml-0 overflow-hidden">

                            <div className="col-sm-6 ">
                                <TaskPieChart />
                            </div>
                            <div className="col-sm-6 TaskDetails ">
                                <Accordion />
                            </div>
                            {/* <div >
                            <Prioritybarchart />
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </GlobalLayout>
    );
};

export default Home;
