import { auth, db } from "../../../firebase.js";
import { collection, getDocs } from "firebase/firestore";
export async function fetchMeeting() {
    const currentUser = auth.currentUser;
    const user = currentUser.displayName;
    try {
        const meetingsCollectionRef = collection(db, "Meetings");
        const meetingsSnapshot = await getDocs(meetingsCollectionRef);
        const ids = meetingsSnapshot.docs.map((doc) => doc.id);

        const data = [];
        for (const meetingDoc of ids) {
            const meetCollectionRef = collection(db, "Meetings", meetingDoc, "Meet");
            const meetSnapshot = await getDocs(meetCollectionRef);
            const meetData = meetSnapshot.docs.map((doc) => ({ ...doc.data() }));
            data.push(...meetData);
        }

        // meetingData.push(data);
        const allTasks = data.reduce(
            (allTasks, meeting) => allTasks.concat(meeting.tasks),
            []
        );

        return allTasks.filter((task) => {
            return task.actionBy === user;
        });
    } catch (error) {
        console.error("Error fetching meeting data:", error);
    }
}
