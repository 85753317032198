import React from 'react';
import PropTypes from 'prop-types';

import './buttonStyles.css';

const Button = ({ value, styleClass, onClick }) => (
    <button
        className={`btn  ${styleClass}`}
        onClick={(event) => onClick(event)}>
        {value}
    </button>
);

Button.propTypes = {
    styleClass: PropTypes.string,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

Button.defaultProps = {
    styleClass: 'btn-primary btn-sm'
};

export default Button;