import React from "react";
import UserCard from "../utils/elements/UserCard";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";

export default function Header() {
  const user = auth.currentUser;
  const photoURL = user.photoURL;

  return (
    <>
      <div
        style={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
        className="flex p-1"
      >



        <div>

          <img
            className="ml-auto mr-auto w-[60%] md:w-[20%] lg:w-[18%]"

            src={process.env.PUBLIC_URL + '/images/pump-logo-hd2.png'}
            alt=""
          />
        </div>
        <div className="relative user w-1/6 h-full">
          <div className="group w-full flex flex-col items-center py-2 pr-2">
            <div className="user-name w-full bg-slate-200 text-black rounded-sm px-2 m-1 text-center">
              <p className="font-bold text-xs md:text-base lg:text-base">
                {user.displayName}
              </p>
            </div>
            {/*  Below is the User Card */}
            {/* <div className="hidden group-hover:block absolute z-50 top-12 right-14 transition transform translate-y-8 ease-in-out">
              <UserCard userPic={photoURL} user={user} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
