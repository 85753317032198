import React, { useRef, useEffect } from 'react';
import './allmodals.css'

// for Modal reusability
// https://deadsimplechat.com/blog/creating-a-reusable-pop-up-modal-in-react-from-scratch/

function ModalConfirm({ children, saveData, onClose }) {

    const btnRef = useRef();

    useEffect(() => {

        btnRef.current.focus();

    }, [])

    return (
        <div className='modaloverlay'>
            <div className='modaldisplay'>
                <header className="flex">
                    <img width='50px ' src={process.env.PUBLIC_URL + "/images/icons/warning.png"} alt="none" />
                    <span style={{ marginLeft: '20px', fontSize: '32px', fontWeight: 'bold' }}>
                        Confirm
                    </span>
                </header>
                <hr></hr>

                <div className="row mb-4">
                    {children}
                </div>
                <hr></hr>
                <div className="row" style={{ marginTop: '15px' }}>
                    <div className="col-sm-6 text-start">
                        <button className='btn btn-success buttonWidth100' onClick={saveData}>Yes</button>
                    </div>
                    <div className="col-sm-6 text-end">
                        <button className='btn btn-danger buttonWidth100' ref={btnRef} onClick={onClose} >No</button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ModalConfirm;
