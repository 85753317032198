import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { signOut } from "firebase/auth";
import { app, auth, } from '../../firebase';
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import MenuIcon from '@mui/icons-material/Menu';
import './navbar.css'
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

let SIDEBAR_SUBMENU = [];



const Navbar = () => {
  const [toggled, setToggled] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(true)
  const [userData, setUserData] = useState([])


  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const currentRoute = location.pathname;
  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      const db = getFirestore(app);
      const userDocRef = doc(db, 'Users', user.email);
      const userSnapshot = await getDoc(userDocRef);
      const userD = userSnapshot.data();
      setUserData(userD);
    };

    fetchUserData();

  }, []);

  // useEffect(() => {

  if (user.email === "it@pumpacademy.in") {
    SIDEBAR_SUBMENU = [
      {
        id: 1,
        name: "Home",
        path: "/home",
        icon: <i className="bi bi-house-door-fill"></i>,
      },
      {
        id: 2,
        name: "New Meeting Minutes",
        path: "/newmeetmins",
        icon: <i className="bi bi-pencil-square"></i>,
      },
      {
        id: 3,
        name: "Meeting History",
        path: "/meethistory2",
        icon: <i className="bi bi-clock-history"></i>,
      },
      {
        id: 4,
        name: "My Tasks",
        path: "/Task",
        icon: <FormatListBulletedIcon />,
      },
      {
        id: 5,
        name: "My Subtasks",
        path: "/subtasks",
        icon: <LowPriorityIcon />,
      },
      {
        id: 6,
        name: "Add Profile",
        path: "/newProfile",
        icon: <i className="bi bi-person-plus"></i>,
      },
      {
        id: 7,
        name: "Add Codes",
        path: "/newCodes",
        icon: <i className="bi bi-file-earmark-plus"></i>,
      },
      {
        id: 8,
        name: "Settings",
        path: "/profile",
        icon: <i className="bi bi-gear-fill"></i>,
      },
      {
        id: 9,
        name: "Help & Support",
        path: "/helpSupport",
        icon: <i className="bi bi-patch-question-fill"></i>,
      },
    ];
  } else {
    SIDEBAR_SUBMENU = [
      {
        id: 1,
        name: "Home",
        path: "/home",
        icon: <i className="bi bi-house-door-fill"></i>,
      },
      {
        id: 2,
        name: "New Meeting Minutes",
        path: "/newmeetmins",
        icon: <i className="bi bi-pencil-square"></i>,
      },
      {
        id: 3,
        name: "Meeting History",
        path: "/meethistory2",
        icon: <i className="bi bi-clock-history"></i>,
      },
      {
        id: 4,
        name: "My Tasks",
        path: "/Task",
        icon: <FormatListBulletedIcon />,
      },
      {
        id: 5,
        name: "My Subtasks",
        path: "/subtasks",
        icon: <LowPriorityIcon />,
      },
      {
        id: 6,
        name: "Settings",
        path: "/profile",
        icon: <i className="bi bi-gear-fill"></i>,
      },

      {
        id: 7,
        name: "Help & Support",
        path: "/helpSupport",
        icon: <i className="bi bi-patch-question-fill"></i>,
      },
    ];
  }

  // }, [])





  useEffect(() => {


    if (window.innerWidth <= 600) {
      setIsMobile(true)
      setShowSidebar(false)

    }
    else {
      setIsMobile(false)
      setShowSidebar(true)
    }

  }, [])




  const handleOpenSubMenu = () => {
    setSubmenuOpen(!submenuOpen)
  }

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");

        alert.success("Signed out successfully");
      })
      .catch((error) => {
        alert.error(error);
      });
  };

  // var domDrop = document.querySelector('ps-sidebar-backdrop')
  const collection = document.getElementsByClassName("ps-sidebar-backdrop");

  if (collection.length > 0) {
    collection[0].addEventListener("click", function () {
      setShowSidebar(false)
    })
  }

  return (
    <>
      <div className="" style={{ display: "flex" }}>
        {showSidebar && (
          <Sidebar
            toggled={toggled}
            customBreakPoint="800px"
            onBreakPoint={setIsMobile}
            backgroundColor="#252c48"
            className="text-white "
          >
            <div className="p-1">
              <img src={process.env.PUBLIC_URL + "/images/iminutes-logo-white.png"} alt="" />
            </div>

            <Menu
              transitionDuration={1000}
              menuItemStyles={{
                button: {
                  fontWeight: "bold",
                  padding: "15%",
                  "&.active": {
                    backgroundColor: "#335B8C !important",
                    color: "white !important",
                  },
                  "&:hover": {
                    backgroundColor: "#335B8C !important",
                    color: "white !important",
                  },
                },
              }}
            >
              {SIDEBAR_SUBMENU.map((item, index) => (
                <MenuItem key={index}
                  component={
                    <Link
                      className={
                        currentRoute === item.path ? "tab active" : "tab"
                      }
                      to={item.path}
                    />
                  }
                >
                  {item.icon} &nbsp; {item.name}
                </MenuItem>
              ))}

              <MenuItem className="borderBottomMenu" onClick={handleLogout}>
                <i className="bi bi-box-arrow-in-right"></i>
                &nbsp;&nbsp;&nbsp;Logout
              </MenuItem>
            </Menu>
          </Sidebar>
        )}

        <main className="">
          {/* {isMobile && ( */}
          <div
            className="sb-button cursor-pointer text-black absolute top-5 ml-5 text-4xl"
            onClick={() => {
              // console.log("outer")

              if (window.innerWidth <= 600) {
                setIsMobile(true);
                setShowSidebar(!showSidebar);
                // console.log("inner")
              } else {
                setShowSidebar(!showSidebar);
                setIsMobile(false);
              }
            }}
          >
            <MenuIcon />
          </div>
          {/* )} */}
        </main>
      </div>
    </>
  );
};

export default Navbar;
