import React, { useEffect, useState } from "react";
import "./allmodals.css";
import { db } from "../../../../firebase";
import { query, collection, onSnapshot } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { format } from "date-fns";
import HtmlDropdown from "../HtmlSelect";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useForm } from "react-hook-form";


// for Modal reusability
// https://deadsimplechat.com/blog/creating-a-reusable-pop-up-modal-in-react-from-scratch/

function ModalAddAgenda({
  addEdit,
  userList,
  onClose,
  saveDataToParent,
  agendaItems,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [agenda, setAgenda] = useState("");
  const [discussionPoints, setDiscussionPoints] = useState("");
  const [selectedDept, setSelectedDept] = useState({
    value: 0,
    label: "Select Department",
  });
  const [newDeptData, setNewDeptData] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [targetDate, setTargetDate] = useState(new Date());

  const [error, setError] = useState({});

  const [selectedActionBy, setSelectedActionBy] = useState("");
  const [selectedActionFor, setSelectedActionFor] = useState({});
  const [supporterData, setSupporterData] = useState([]);
  const [mode, setMode] = useState(addEdit);
  const [isDisabled, setIsDisabled] = useState(false);
  const agendaItemsNos = agendaItems.length;
  var todayDate = firebase.firestore.Timestamp.now().toDate();
  const [actionBy, setActionBy] = useState([
    {
      value: 0,
      label: "Select Action By",
    },
  ]);
  const [actionForData, setActionForData] = useState([
    {
      value: 0,
      label: "Select Action For",
    },
  ]);
  const [subtask1, setSubtask1] = useState({
    subtaskName: "",
    supportedBy: {
      value: 0,
      label: "Select Supporter",
    },
    targetDate: new Date(),
    status: "Ongoing",
  });

  const [subtask2, setSubtask2] = useState({
    subtaskName: "",
    supportedBy: {
      value: 0,
      label: "Select Supporter",
    },
    targetDate: new Date(),
    status: "Ongoing",
  });

  const [subtask3, setSubtask3] = useState({
    subtaskName: "",
    supportedBy: {
      value: 0,
      label: "Select Supporter",
    },
    targetDate: new Date(),
    status: "Ongoing",
  });

  const [subtask4, setSubtask4] = useState({
    subtaskName: "",
    supportedBy: {
      value: 0,
      label: "Select Supporter",
    },
    targetDate: new Date(),
    status: "Ongoing",
  });

  // const btnRef = useRef();


  // useEffect(() => {
  //   btnRef.current.focus();
  // }, []);

  const handleChangeAgendaData = (e) => {
    setAgenda(e.target.value);
  };
  const handleDiscussionPoints = (e) => {
    setDiscussionPoints(e.target.value);
  };

  const handleDropdownDepartment = (ddData) => {
    setSelectedDept(ddData);
    setValue("selectedDept", ddData);
    if (ddData) {
      if (ddData.label === "Select Department") {
        // Reset action by dropdown to default option
        setSelectedActionBy({
          value: 0,
          label: "Select Action By",
        });
        // Set action by options to default option only
        setActionBy([
          {
            value: 0,
            label: "Select Action By",
          },
        ]);
      } else {
        var counter = 0;
        setSelectedActionBy({
          value: 0,
          label: "Select Action By",
        });
        // Set a
        // Filter users based on selected department and set action by options
        setActionBy(
          userList.reduce(
            (acc, user) => {
              if (user.department.label === ddData.label) {
                acc.push({
                  value: counter + 1,
                  label: user.name,
                });
                counter = counter + 1;
              }
              return acc;
            },
            [
              {
                value: 0,
                label: "Select Action By",
              },
            ]
          )
        );

      }
    }
  };

  const handleDropdownActionBy = (selectedOption) => {

    setSelectedActionBy(selectedOption);
    setValue("selectedActionBy", selectedOption);
  };


  useEffect(() => {
    var counter = 0;
    setActionForData(
      userList.reduce(
        (acc, user) => {
          if (user.isManager) {
            acc.push({
              value: counter + 1,
              label: user.name,
            });
            counter = counter + 1;
          }
          return acc;
        },
        [
          {
            value: 0,
            label: "Select Action For",
          },
        ]
      )
    );
  }, [userList]);

  const handleDropdownActionFor = (selectedOption) => {
    setSelectedActionFor(selectedOption);
    setValue("selectedActionFor", selectedOption);


  };



  useEffect(() => {
    var counter = 0;
    setSupporterData(
      userList.reduce(
        (acc, user) => {
          if (user) {
            acc.push({
              value: counter + 1,
              label: user.name,
            });
            counter = counter + 1;
          }
          return acc;
        },
        [
          {
            value: 0,
            label: "Select Supporter",
          },
        ]
      )
    );
  }, [userList]);

  const handleChangeSubtask1 = (e) => {
    setSubtask1((prevState) => ({
      ...prevState,
      subtaskName: e.target.value,
    }));


  };

  const handleSupportedBy1 = (value) => {
    setSubtask1((prevState) => ({
      ...prevState,
      supportedBy: value,
    }));
  };

  const handleTargetDate1 = (date) => {

    setSubtask1((prevState) => ({
      ...prevState,
      targetDate: date,
    }));
  };


  const handleChangeSubtask2 = (e) => {
    setSubtask2((prevState) => ({
      ...prevState,
      subtaskName: e.target.value,
    }));
  };

  const handleSupportedBy2 = (value) => {
    setSubtask2((prevState) => ({
      ...prevState,
      supportedBy: value,
    }));
  };

  const handleTargetDate2 = (date) => {

    setSubtask2((prevState) => ({
      ...prevState,
      targetDate: date,
    }));
  };


  const handleChangeSubtask3 = (e) => {
    setSubtask3((prevState) => ({
      ...prevState,
      subtaskName: e.target.value,
    }));
  };

  const handleSupportedBy3 = (value) => {
    setSubtask3((prevState) => ({
      ...prevState,
      supportedBy: value,
    }));
  };

  const handleTargetDate3 = (date) => {

    setSubtask3((prevState) => ({
      ...prevState,
      targetDate: date,
    }));
  };


  const handleChangeSubtask4 = (e) => {
    setSubtask4((prevState) => ({
      ...prevState,
      subtaskName: e.target.value,
    }));
  };

  const handleSupportedBy4 = (value) => {
    setSubtask4((prevState) => ({
      ...prevState,
      supportedBy: value,
    }));
  };

  const handleTargetDate4 = (date) => {
    setSubtask4((prevState) => ({
      ...prevState,
      targetDate: date,
    }));
  };


  const createNewArray = (existingArray, arrayName) => {

    if (arrayName === "Department") {
      let newDeptDataVariable = existingArray.map((obj, index) => ({
        value: index + 1,
        label: obj,
      }));

      newDeptDataVariable.unshift({
        value: 0,
        label: "Select Department",
      });

      setNewDeptData(newDeptDataVariable);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const fetchedDocIDs = [];
      const q = query(collection(db, "Department"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedDocIDs.push(doc.id);
        });

        createNewArray(fetchedDocIDs, "Department");
      });

      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);

  const compareDates = () => {


    // const targetDateObj = new Date(targetDate);
    // const subtaskDateObj = new Date(subtask1.targetDate);

    // if (targetDateObj > subtaskDateObj) {
    //   alert.error("targetDate is less than subtask1.targetDate");
    // } else {

    // }
  };


  //*** Validation

  //?  https://leanylabs.com/blog/form-validation-in-react/

  const validateValues = (inputValues) => {

    let error = {};
    if (agenda.length < 1) {
      error.agenda = "Agenda is required";
    }
    if (discussionPoints.length < 1) {
      error.discussionPoints = "Discussion Points are required";
    }
    if (subtask1.subtaskName && subtask1.supportedBy.value === 0) {
      error.supportedBy1 = "Select Supporter for Subtask1";
    }
    if (!subtask1.subtaskName && subtask1.supportedBy.value !== 0) {
      setSubtask1({
        supportedBy: 0,
      });
    }

    if (subtask2.subtaskName && subtask2.supportedBy.value === 0) {
      error.supportedBy2 = "Select Supporter for Subtask2";
    }
    if (!subtask2.subtaskName && subtask2.supportedBy.value !== 0) {
      setSubtask2({
        supportedBy: 0,
      });
    }

    if (subtask3.subtaskName && subtask3.supportedBy.value === 0) {
      error.supportedBy3 = "Select Supporter for Subtask3";
    }
    if (!subtask3.subtaskName && subtask3.supportedBy.value !== 0) {
      setSubtask3({
        supportedBy: 0,
      });
    }

    if (subtask4.subtaskName && subtask4.supportedBy.value === 0) {
      error.supportedBy4 = "Select Supporter for Subtask4";
    }
    if (!subtask4.subtaskName && subtask4.supportedBy.value !== 0) {
      setSubtask4({
        supportedBy: 0,
      });
    }

    return error;
  };

  // ****

  //***** Edit mode Code below

  useEffect(() => {
    if (mode !== -1) {


      setAgenda(agendaItems[mode].agenda);
      setDiscussionPoints(agendaItems[mode].discussionPoints);

      setSelectedDept({
        value: 0,
        label: agendaItems[mode].department,
      });
      setSelectedActionBy({
        value: 0,
        label: agendaItems[mode].actionBy,
      });

      setSelectedActionFor({
        value: 0,
        label: agendaItems[mode].actionFor,
      });

      setStartDate(new Date(agendaItems[mode].startDateISO));
      setTargetDate(new Date(agendaItems[mode].targetDateISO));


      if (agendaItems[mode].subtask1) {
        const s1Obj = JSON.parse(agendaItems[mode].subtask1);
        setSubtask1({
          subtaskName: s1Obj.subtaskName || "",
          supportedBy: s1Obj.supportedBy || "",
          targetDate: agendaItems[mode].s1TargetDate,
        });
      }

      if (agendaItems[mode].subtask2) {
        const s2Obj = JSON.parse(agendaItems[mode].subtask2);

        setSubtask2({
          subtaskName: s2Obj.subtaskName || "",
          supportedBy: s2Obj.supportedBy || "",
          targetDate: agendaItems[mode].s2TargetDate,
        });
      }

      if (agendaItems[mode].subtask3) {
        const s3Obj = JSON.parse(agendaItems[mode].subtask3);

        setSubtask3({
          subtaskName: s3Obj.subtaskName || "",
          supportedBy: s3Obj.supportedBy || "",
          targetDate: agendaItems[mode].s3TargetDate,
        });
      }

      if (agendaItems[mode].subtask4) {
        const s4Obj = JSON.parse(agendaItems[mode].subtask4);

        setSubtask4({
          subtaskName: s4Obj.subtaskName || "",
          supportedBy: s4Obj.supportedBy || "",
          targetDate: agendaItems[mode].s4TargetDate,
        });
      }

      setIsDisabled(true);


    }
  }, [mode, agendaItems]);

  // *** Sending Data to the Parent
  const callData = () => {
    const agendaData = {
      no: agendaItemsNos + 1,
      agenda: agenda,
      discussionPoints: discussionPoints,
      department: selectedDept.label,
      actionBy: selectedActionBy.label,
      actionFor: selectedActionFor.label,
      status: "Ongoing",
      startDate: format(startDate, "dd-MM-yyyy").toString(),
      targetDate: format(targetDate, "dd-MM-yyyy").toString(),
      subtask1: JSON.stringify(subtask1),
      subtask2: JSON.stringify(subtask2),
      subtask3: JSON.stringify(subtask3),
      subtask4: JSON.stringify(subtask4),
      startDateISO: startDate.toString(),
      targetDateISO: targetDate.toString(),
      s1TargetDate: subtask1.targetDate,
      s2TargetDate: subtask2.targetDate,
      s3TargetDate: subtask3.targetDate,
      s4TargetDate: subtask4.targetDate,

    };

    // Invoke the saveData callback with the agendaData
    saveDataToParent(agendaData);

    onClose(); // Close the modal
  };
  const confirmData = () => {

    var returnError = validateValues();

    if (Object.keys(returnError).length > 0) {
      if (returnError.supportedBy1) {
        setError(returnError);
      }
      if (returnError.supportedBy2) {
        setError(returnError);
      }
      if (returnError.supportedBy3) {
        setError(returnError);
      }
      if (returnError.supportedBy4) {
        setError(returnError);
      }
      if (returnError.agenda) {
        setError(returnError);
      }
      if (returnError.discussionPoints) {
        setError(returnError);
      }
    } else {
      callData();
    }

  };

  return (


    <div className="modaloverlay">

      <div
        style={{ maxWidth: "100%" }}
        className="modaldisplay flex h-[700px] md:h-[80%] lg:h-[80%] flex-col "
      >
        <header className="flex border-b-2 border-gray-400 pb-2">
          {mode === -1 ? (
            <img width="40px " src={process.env.PUBLIC_URL + "/images/icons/add.jpg"} alt="Add new icon" />
          ) : (
            <img width="40px " src={process.env.PUBLIC_URL + "/images/icons/edit.webp"} alt="Edit icon" />
          )}

          <span className="ms-3 text-2xl font-bold">
            {mode === -1 ? " Add New Task" : " Edit Task"}
          </span>
        </header>

        <form onSubmit={handleSubmit(confirmData)} className="overflow-auto">
          <div className="mx-3">
            <div className="row g-3 mt-3">
              <div className="col-sm-6 ">
                <TextField
                  style={{ width: "100%", margin: "0px" }}
                  id="outlined-textarea"

                  value={agenda || ""}
                  onChange={handleChangeAgendaData}
                  label="Task Name"
                  rows="1"
                  placeholder="Task Name"
                  multiline
                />
                <div>
                  {error.agenda ? (
                    <p className="error text-red-600">{error.agenda}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <TextField
                  style={{ width: "100%", margin: "0px" }}
                  id="outlined-textarea"

                  value={discussionPoints || ""}
                  onChange={handleDiscussionPoints}
                  label="Discussion Points"
                  rows="2"
                  placeholder="Discussion Points"
                  multiline
                />
                <div>
                  {error.discussionPoints ? (
                    <p className="error text-red-600">
                      {error.discussionPoints}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row g-3 mt-3">
              {mode === -1 ? (
                <>
                  <div className="col-sm-2">
                    <p>Select Department</p>
                    <Select
                      {...register("selectedDept", {
                        required: "Select Department is required",
                      })}
                      value={selectedDept}
                      onChange={handleDropdownDepartment}
                      options={newDeptData}
                      placeholder="Select Department"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}

                    />
                    <p
                      style={{ marginTop: "1%" }}
                      className="text-red-500 font-semibold "
                    >
                      {errors.selectedDept?.message && (
                        <span>
                          <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                          {errors.selectedDept?.message}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-2">
                    <p>Action By</p>
                    <Select
                      value={selectedActionBy}
                      {...register("selectedActionBy", {
                        required: "Select Action By is required",
                      })}
                      onChange={handleDropdownActionBy}
                      options={actionBy}
                      placeholder="Select Action By"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    <p
                      style={{ marginTop: "1%" }}
                      className="text-red-500 font-semibold "
                    >
                      {errors.selectedActionBy?.message && (
                        <span>
                          <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                          {errors.selectedActionBy?.message}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-2">
                    <HtmlDropdown
                      data={actionForData}
                      {...register("selectedActionFor", {
                        required: "Select Action By is required",
                      })}
                      margin="0"
                      onChange={handleDropdownActionFor}
                      placeholder="Action For"
                      value={selectedActionFor.value}
                      defaultValue={selectedActionFor.value}
                    />
                    <p
                      style={{ marginTop: "1%" }}
                      className="text-red-500 font-semibold "
                    >
                      {errors.selectedActionFor?.message && (
                        <span>
                          <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                          {errors.selectedActionFor?.message}
                        </span>
                      )}
                    </p>

                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-2">
                    <p>Select Department</p>
                    <input
                      value={selectedDept.label}
                      type="text"
                      className="border-1 p-2 bg-gray-100"
                      readOnly
                    />
                  </div>
                  <div className="col-sm-2">
                    <p>Action By</p>
                    <input
                      value={selectedActionBy.label}
                      type="text"
                      className="border-1 p-2 bg-gray-100"
                      readOnly
                    />
                  </div>
                  <div className="col-sm-2">
                    <p>Action For</p>
                    <input
                      value={selectedActionFor.label}
                      type="text"
                      className="border-1 p-2 bg-gray-100"
                      readOnly
                    />
                  </div>
                </>
              )}

              <div className="col-sm-4">

                <div className="main flex justify-between md:justify-around lg:justify-around">
                  <div className="">
                    <p>Start Date</p>
                    <DatePicker
                      className="datepicker border-1 border-gray-700 "
                      showIcon
                      dateFormat="dd-MM-yyyy"
                      minDate={todayDate}
                      selected={startDate}
                      startDate={startDate}

                      endDate={targetDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div className="">
                    <p>Target Date</p>

                    <DatePicker
                      className="datepicker border-1 border-gray-700 "
                      showIcon
                      dateFormat="dd-MM-yyyy"
                      selected={targetDate}
                      selectsEnd
                      startDate={startDate}
                      minDate={startDate}
                      endDate={targetDate}
                      onChange={(date) => {
                        setTargetDate(date);
                        compareDates();
                      }}
                    />
                  </div>

                </div>


              </div>
            </div>

            <div className="row g-3 mt-3 rounded-md border shadow-md ">

              <p className="text-lg font-bold my-2">Subtasks</p>
              <div className="col-sm-4 mt-0">
                <TextField
                  style={{ width: "100%" }}
                  value={subtask1.subtaskName}
                  id="outlined-basic"
                  name="subtask1"
                  label="Subtask 1"
                  variant="outlined"
                  onChange={(e) => handleChangeSubtask1(e)}
                />
                {error.subtaskName1 && (
                  <p className="text-red-600 mt-1">{error.subtaskName1}</p>
                )}
              </div>




              <div className="col-sm-4 mt-0">
                <HtmlDropdown
                  data={supporterData}
                  onChange={handleSupportedBy1}
                  placeholder=""
                  value={subtask1.supportedBy.value}
                  defaultValue={subtask1.supportedBy.value}
                  margin="0"
                />
                {error.supportedBy1 ? (
                  <p className="error text-red-600">{error.supportedBy1}</p>
                ) : null}

              </div>

              <div className="col-sm-3 mt-0 mb-2 ml-2 md:ml-8 lg:ml-8 flex gap-2">
                <p>Target Date</p>
                <DatePicker
                  className="datepicker red-border border-1 border-gray-700 "
                  showIcon
                  border="1px solid black"
                  style={{ border: "solid 1px pink" }}
                  dateFormat="dd-MM-yyyy"
                  selected={subtask1.targetDate}

                  startDate={todayDate}

                  minDate={todayDate}
                  maxDate={targetDate}
                  onChange={(date) => {
                    handleTargetDate1(date);
                  }}
                />
              </div>


            </div>

            <div className="row g-3 mt-3 pb-2 rounded-md  shadow-md ">

              <div className="col-sm-4">
                <TextField
                  style={{ width: "100%" }}
                  value={subtask2.subtaskName}
                  id="outlined-basic"
                  name="subtask2"
                  label="Subtask 2"
                  variant="outlined"
                  onChange={(e) => handleChangeSubtask2(e)}
                />
                {error.subtaskName2 ? (
                  <p className="error text-red-600">{error.subtaskName2}</p>
                ) : null}
              </div>
              <div className="col-sm-4">
                <HtmlDropdown
                  data={supporterData}
                  onChange={handleSupportedBy2}
                  placeholder=""
                  value={subtask2.supportedBy.value}
                  defaultValue={subtask2.supportedBy.value}
                  margin="0"
                />

                {error.supportedBy2 ? (
                  <p className="error text-red-600">{error.supportedBy2}</p>
                ) : null}
              </div>

              <div className="col-sm-3  mb-2 ml-2 md:ml-8 lg:ml-8 flex gap-2">
                <p>Target Date</p>
                <DatePicker
                  className="datepicker red-border border-1 border-gray-700 "
                  showIcon
                  border="1px solid black"
                  style={{ border: "solid 1px pink" }}
                  dateFormat="dd-MM-yyyy"
                  selected={subtask2.targetDate}
                  startDate={todayDate}
                  minDate={todayDate}
                  maxDate={targetDate}
                  onChange={(date) => {
                    handleTargetDate2(date);
                  }}
                />
              </div>

            </div>

            <div className="row g-3 mt-3 pb-2 rounded-md  shadow-md ">

              <div className="col-sm-4 ">
                <TextField
                  style={{ width: "100%" }}
                  value={subtask3.subtaskName}
                  id="outlined-basic"
                  name="subtask3"
                  label="Subtask 3"
                  variant="outlined"
                  onChange={(e) => handleChangeSubtask3(e)}
                />

                {error.subtaskName ? (
                  <p className="error text-red-600">{error.subtaskName}</p>
                ) : null}
              </div>
              <div className="col-sm-4">
                <HtmlDropdown
                  data={supporterData}
                  onChange={handleSupportedBy3}
                  placeholder=""
                  value={subtask3.supportedBy.value}
                  defaultValue={subtask3.supportedBy.value}
                  margin="0"
                />

                {error.supportedBy3 ? (
                  <p className="error text-red-600">{error.supportedBy3}</p>
                ) : null}
              </div>

              <div className="col-sm-3  mb-2 ml-2 md:ml-8 lg:ml-8 flex gap-2">
                <p>Target Date</p>
                <DatePicker
                  className="datepicker red-border border-1 border-gray-700 "
                  showIcon
                  border="1px solid black"
                  style={{ border: "solid 1px pink" }}
                  dateFormat="dd-MM-yyyy"
                  selected={subtask3.targetDate}
                  startDate={todayDate}
                  minDate={todayDate}
                  maxDate={targetDate}
                  onChange={(date) => {
                    handleTargetDate3(date);
                  }}
                />
              </div>


            </div>

            <div className="row g-3 mt-3 pb-2 rounded-md shadow-md ">

              <div className="col-sm-4">
                <TextField
                  style={{ width: "100%" }}
                  value={subtask4.subtaskName}
                  id="outlined-basic"
                  name="subtask4"
                  label="Subtask 4"
                  variant="outlined"
                  onChange={(e) => handleChangeSubtask4(e)}
                />
                {error.subtaskName4 ? (
                  <p className="error text-red-600">{error.subtaskName4}</p>
                ) : null}
              </div>
              <div className="col-sm-4">
                <HtmlDropdown
                  data={supporterData}
                  onChange={handleSupportedBy4}
                  placeholder=""
                  value={subtask4.supportedBy.value}
                  defaultValue={subtask4.supportedBy.value}
                  margin="0"
                />
                {error.supportedBy4 ? (
                  <p className="error text-red-600">{error.supportedBy4}</p>
                ) : null}
              </div>

              <div className="col-sm-3  mb-2 ml-2 md:ml-8 lg:ml-8 flex gap-2">
                <p>Target Date</p>
                <DatePicker
                  className="datepicker red-border border-1 border-gray-700 "
                  showIcon
                  border="1px solid black"
                  style={{ border: "solid 1px pink" }}
                  dateFormat="dd-MM-yyyy"
                  selected={subtask4.targetDate}
                  startDate={todayDate}
                  minDate={todayDate}
                  maxDate={targetDate}
                  onChange={(date) => {
                    handleTargetDate4(date);
                  }}
                />
              </div>

            </div>
          </div>
          <footer className="flex justify-between border-t-2 mt-4 border-gray-400 pt-2">
            <div className="text-start">
              <input
                type="submit"
                value="Confirm"
                className="btn btn-success bg-green-600 buttonWidth100"

              ></input>
            </div>

            <div className="text-end">
              <button
                className="btn btn-danger buttonWidth100"
                // ref={btnRef}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </footer>
        </form>
      </div>
    </div>

  );
}


export default ModalAddAgenda;
