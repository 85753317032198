import React, { useState, Fragment, useEffect } from "react";
import GlobalLayout from "../../utils/hoc/globalLayout.js";
import DatePicker from "react-datepicker";
import { Tab } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";
import { auth, db } from "../../../firebase.js";
import { collection, getDocs } from "firebase/firestore";
import "firebase/compat/firestore";
import { format } from "date-fns";

const CompletedTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const currentUser = auth.currentUser;
    const user = currentUser.displayName;

    // Function to clear the current comment input

    useEffect(() => {
        const fetchMeetingData = async () => {
            try {
                const meetingsCollectionRef = collection(db, "Meetings");
                const meetingsSnapshot = await getDocs(meetingsCollectionRef);
                const ids = meetingsSnapshot.docs.map((doc) => doc.id);

                const data = [];
                for (const meetingDoc of ids) {
                    const meetCollectionRef = collection(
                        db,
                        "Meetings",
                        meetingDoc,
                        "Meet"
                    );
                    const meetSnapshot = await getDocs(meetCollectionRef);
                    const meetData = meetSnapshot.docs.map((doc) => ({ ...doc.data() }));
                    data.push(...meetData);
                }


                const allTasks = data.reduce(
                    (allTasks, meeting) => allTasks.concat(meeting.tasks),
                    []
                );

                setTasks(
                    allTasks.filter((task) => {


                        return (
                            task.actionBy === user &&
                            // && (task.targetDate && isBefore(newTargetDate, timestamp))
                            task.status &&
                            task.status === "Completed"
                            //  && (task.status && task.status !== "Ongoing")
                        );
                    })
                );
            } catch (error) {
                alert.error("Error fetching meeting data:", error);
            }
        };

        fetchMeetingData();
    }, [user]);

    // Trigger the effect when documentIds or user changes

    return (
        <GlobalLayout>
            <div className="bg-lime-500 w-[99%] text-blue-900 text-center text-base p-1">
                <p>Thank You for completing the Tasks🎉🥳👏</p>
            </div>
            <div className="flex flex-col md:flex-row w-fit lg:w-full border-transparent rounded shadow-lg p-1 mr-3">
                <div className="sidebar overflow-y-auto md:overflow-x-auto lg:overflow-x-auto border w-[430px] md:w-1/5 lg:w-1/5">
                    <div className="side-list h-fit p-2 flex md:flex-col lg:flex-col gap-4">
                        <Tab.Group
                            defaultIndex={selectedTab}
                            onChange={(index) => setSelectedTab(index)}
                        >
                            {tasks.map((task, index) => (
                                <Tab className="" key={index}>
                                    {({ selected }) => (
                                        <div
                                            className={
                                                "" +
                                                (selected
                                                    ? " bg-light-900 text-blue-900 border-2 border-blue-600 bg-sky-100 shadow "
                                                    : "text-[#252c48] border-2 border-gray-100  hover:bg-white/[2] hover:text-[#252c48]")
                                            }
                                        >
                                            <p className="font-bold m-1">{task.agenda}</p>
                                        </div>
                                    )}
                                </Tab>
                            ))}
                        </Tab.Group>
                    </div>
                </div>

                <div className="main w-full md:w-4/5 lg:w-4/5 border-y border-r">
                    {selectedTab >= 0 && (
                        <>
                            <div className="main-title flex justify-between border-b p-2 ">
                                <p className="text-lg font-bold">
                                    Task ID:{tasks[selectedTab]?.taskUID}
                                </p>

                            </div>
                            <div className="main-body p-2 ">
                                <div className="tasks">
                                    <div className="first-strip shadow-sm  flex items-center justify-between p-2 border px-4 ">
                                        <div className="dropdown">
                                            <select
                                                id="status"
                                                name="status"
                                                defaultValue={tasks[selectedTab]?.status}
                                                className="w-fit h-10 border-transparency focus:outline-none bg-white focus:border-black text-black rounded px-2 md:px-3 py-0 md:py-1 tracking-wider"
                                            >

                                                <option value="Completed">Completed</option>
                                            </select>
                                        </div>
                                        <p className="font-bold">Task Completion Comment: <span className="font-normal">{tasks[selectedTab]?.comment}</span></p>

                                    </div>

                                    <div className="second-strip shadow-sm flex items-center p-2 border px-4 mt-3">
                                        <p className="font-bold">Description:</p> &nbsp;&nbsp;
                                        <p style={{ whiteSpace: "pre-line" }}>{tasks[selectedTab]?.discussionPoints}</p>

                                    </div>

                                    <div className="third-strip shadow-sm flex flex-col gap-2.5 first-letter: p-2 border px-4 mt-3">
                                        <p className="font-bold ">Task Information</p>
                                        <div className="flex flex-col md:flex-row lg:flex-row justify-between">
                                            <p>
                                                Action By : &nbsp;&nbsp;&nbsp;
                                                {tasks[selectedTab]?.actionBy && (
                                                    <span className="border-1 text-base font-semibold bg-blue-100 border-blue-600 p-2 m-1 shadow-sm shadow-blue-700">
                                                        {tasks[selectedTab]?.actionBy}
                                                    </span>
                                                )}
                                            </p>
                                            <p className="mt-4 lg:mt-0">
                                                Action For : &nbsp;&nbsp;&nbsp;
                                                {tasks[selectedTab]?.actionFor && (
                                                    <span className="border-1 text-base font-semibold bg-pink-100 border-pink-600 p-2 m-1 shadow-sm shadow-pink-700">
                                                        {tasks[selectedTab]?.actionFor}
                                                    </span>
                                                )}
                                            </p>
                                        </div>

                                        <div className="flex flex-col md:flex-row lg:flex-row lg:items-center gap-3">
                                            <p>Start Date : </p>
                                            <DatePicker
                                                showIcon
                                                dateFormat="dd/MM/yyyy"
                                                value={tasks[selectedTab]?.startDate}
                                                selectsStart
                                                readOnly
                                                icon="bi bi-calendar-date-fill"
                                            />

                                            <p className="lg:ml-auto">Due Date : </p>
                                            <div className=" lg:mr-auto">
                                                <DatePicker
                                                    showIcon
                                                    dateFormat="dd/MM/yyyy"
                                                    value={tasks[selectedTab]?.targetDate}
                                                    selectsEnd
                                                    readOnly
                                                    icon="bi bi-calendar-date-fill"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fourth-strip mt-4 ">
                                        <Tab.Group>
                                            <Tab.List className="flex space-x-1  rounded-xl bg-light-900 p-1">
                                                {/* <Tab
                                                    as={Fragment}
                                                    className="w-1/6 py-2.5 text-sm font-medium leading-5 "
                                                >
                                                    {({ selected }) => (
                                                       
                                                    <button
                                                        className={
                                                            selected
                                                                ? "bg-light-900 text-blue-900 border-b-2 border-x-2 shadow-sm "
                                                                : "text-[#252c48]  hover:bg-white/[2] hover:text-[#252c48]"
                                                        }
                                                    >
                                                        Comments
                                                    </button>
                                                    )}
                                            </Tab> */}
                                                <Tab
                                                    as={Fragment}
                                                    className="w-1/6 py-2.5 text-sm font-medium leading-5 "
                                                >
                                                    {({ selected }) => (
                                                        /* Use the `selected` state to conditionally style the selected tab. */
                                                        <button
                                                            className={
                                                                selected
                                                                    ? "bg-light-900 text-blue-900 border-b-2 border-x-2 shadow-sm "
                                                                    : "text-[#252c48]  hover:bg-white/[2] hover:text-[#252c48]"
                                                            }
                                                        >
                                                            Subtasks
                                                        </button>
                                                    )}
                                                </Tab>
                                            </Tab.List>
                                            <Tab.Panels className="border shadow m-1 ">
                                                {/* <Tab.Panel className="p-3 overflow-y-auto">
                                                    {comments.map((c, index) => (
                                                        <div key={index} className="flex items-center">
                                                            <div className="flex-none flex flex-col items-center space-y-1 mr-4">
                                                                <img
                                                                    className="rounded-full w-10 h-10"
                                                                    src="/images/icons/user.png"
                                                                    alt="user"
                                                                />
                                                                <a
                                                                    href=" "
                                                                    className="block text-xs hover:underline"
                                                                >
                                                                    Username
                                                                </a>
                                                            </div>
                                                            <div className="flex-1 w-3/4 bg-indigo-400 text-white p-2 rounded-lg mb-2 relative">
                                                                <p className="text-base">{c.text}</p>
                                                                <p className="text-sm text-end">
                                                                    {c.timestamp}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div className="p-4 pb-0">
                                                        <div className="overflow-hidden">
                                                            <textarea
                                                                id="OrderNotes"
                                                                className="w-full resize-none border-x-0 border-t-0 border-gray-200 bg-gray-100 p-2 align-top sm:text-sm"
                                                                rows="6"
                                                                placeholder="Type Comments here..........👇🏻👇🏻"
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}
                                                            ></textarea>

                                                            <div className="flex items-center justify-end gap-2 py-3 border-t-2 border-gray-400">
                                                                <Attachment />
                                                                <button
                                                                    type="button"
                                                                    className="rounded bg-gray-200 px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-600"
                                                                    onClick={clearComment}
                                                                >
                                                                    Clear Comment
                                                                </button>

                                                                <button
                                                                    type="button"
                                                                    className="rounded bg-[#252c48] px-3 py-1.5 text-sm font-medium text-white hover:bg-[#252c48ce]"
                                                                    onClick={addComment}
                                                                >
                                                                    Add Comment
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Panel> */}
                                                <Tab.Panel className="p-3">
                                                    <div className="m-3">

                                                        <table
                                                            key={tasks[selectedTab]?.no}
                                                            className="table-auto border-collapse border border-blue-900"
                                                        >
                                                            <thead>
                                                                {tasks[selectedTab]?.subtask1.subtaskName.length > 0 && (
                                                                    <tr className="bg-blue-100">
                                                                        <th
                                                                            style={{ width: "40%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Subtask Name
                                                                        </th>
                                                                        <th
                                                                            style={{ width: "35%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Supported By
                                                                        </th>
                                                                        <th
                                                                            style={{ width: "45%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Target Date
                                                                        </th>
                                                                        <th
                                                                            style={{ width: "45%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Status
                                                                        </th>
                                                                    </tr>
                                                                )}
                                                            </thead>
                                                            <tbody>
                                                                {tasks[selectedTab]?.subtask1.subtaskName.length > 0 && (
                                                                    <tr className="hover:bg-blue-50">
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask1.subtaskName}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask1.supportedBy.label}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {format(
                                                                                tasks[selectedTab]?.subtask1.targetDate,
                                                                                "dd-MM-yyyy"
                                                                            )}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask1?.status}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {tasks[selectedTab]?.subtask2.subtaskName.length > 0 && (
                                                                    <tr className="hover:bg-blue-50">
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask2.subtaskName}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask2.supportedBy.label}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {format(
                                                                                tasks[selectedTab]?.subtask2.targetDate,
                                                                                "dd-MM-yyyy"
                                                                            )}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask2?.status}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {tasks[selectedTab]?.subtask3.subtaskName.length > 0 && (
                                                                    <tr className="hover:bg-blue-50">
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask3.subtaskName}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask3.supportedBy.label}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {format(
                                                                                tasks[selectedTab]?.subtask3.targetDate,
                                                                                "dd-MM-yyyy"
                                                                            )}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask3?.status}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {tasks[selectedTab]?.subtask4.subtaskName.length > 0 && (
                                                                    <tr className="hover:bg-blue-50">
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask4.subtaskName}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask4.supportedBy.label}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {format(
                                                                                tasks[selectedTab]?.subtask4.targetDate,
                                                                                "dd-MM-yyyy"
                                                                            )}
                                                                        </td>
                                                                        <td className="border border-blue-500 px-4 py-2">
                                                                            {tasks[selectedTab]?.subtask4?.status}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Tab.Panel>
                                            </Tab.Panels>
                                        </Tab.Group>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </GlobalLayout >
    );
};

export default CompletedTasks;
