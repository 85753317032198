import React, { useState, useEffect } from "react";
import GlobalLayout from "../../utils/hoc/globalLayout.js";
import { Tab } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";
import { auth, db } from "../../../firebase.js";
import { useAlert } from "react-alert";
import LoadingSpinner from "../../utils/elements/LoadingSpinner.js";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import {
    doc,
    getDoc,
    collection,
    getDocs,
    updateDoc,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import ModalConfirm from "../../utils/elements/modals/ModalConfirm.js";

function SubTasks() {
    const [tasks, setTasks] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modalState, setModalState] = useState(false);
    const [checked, setChecked] = useState(false);

    const currentUser = auth.currentUser;
    const user = currentUser.displayName;

    const alert = useAlert();

    const firebaseTimestamp = firebase.firestore.Timestamp.now().toDate();

    const timestamp = new Date(firebaseTimestamp);
    timestamp.setHours(0);
    timestamp.setMinutes(0);
    timestamp.setSeconds(0);
    timestamp.setMilliseconds(0);

    useEffect(() => {
        const fetchMeetingData = async () => {
            try {
                const meetingsCollectionRef = collection(db, "Meetings");
                const meetingsSnapshot = await getDocs(meetingsCollectionRef);
                const ids = meetingsSnapshot.docs.map((doc) => doc.id);

                const data = [];
                for (const meetingDoc of ids) {
                    const meetCollectionRef = collection(
                        db,
                        "Meetings",
                        meetingDoc,
                        "Meet"
                    );
                    const meetSnapshot = await getDocs(meetCollectionRef);
                    const meetData = meetSnapshot.docs.map((doc) => ({ ...doc.data() }));
                    data.push(...meetData);
                }

                const allTasks = data.reduce(
                    (allTasks, meeting) => allTasks.concat(meeting.tasks),
                    []
                );

                setTasks(
                    allTasks.filter((task) => {
                        return (
                            (
                                (task.subtask1.supportedBy.label === user && task.subtask1.status === "Ongoing") ||
                                (task.subtask2.supportedBy.label === user && task.subtask2.status === "Ongoing") ||
                                (task.subtask3.supportedBy.label === user && task.subtask3.status === "Ongoing") ||
                                (task.subtask4.supportedBy.label === user && task.subtask4.status === "Ongoing")
                            ) &&
                            task.status === "Ongoing"
                        );
                    })
                );
                setLoading(false);
            } catch (error) {
                console.error("Error fetching meeting data:", error);
            }
        };

        fetchMeetingData();
    }, [checked, user]);
    const showHideModalConfirm = () => {
        setChecked(false);
        if (modalState) {
            setModalState(false);
        } else {
            setModalState(true);
        }
    };


    const updateSubTaskStatus = async (
        agendaMeetCode,
        taskId,
        subtask,
        status
    ) => {
        try {
            const meetingsCollectionRef = collection(db, "Meetings");
            const meetingsSnapshot = await getDocs(meetingsCollectionRef);
            const ids = meetingsSnapshot.docs.map((doc) => doc.id);

            for (const meetingIds of ids) {
                const taskDocumentRef = doc(
                    db,
                    "Meetings",
                    meetingIds,
                    "Meet",
                    agendaMeetCode
                );
                const taskSnapshot = await getDoc(taskDocumentRef);

                const meetData = taskSnapshot.data();

                if (meetData) {
                    meetData.tasks.forEach((task) => {
                        if (task.taskID === taskId) {
                            switch (subtask) {
                                case "Subtask1":
                                    task.subtask1.status = status;
                                    alert.success("Subtask 1 updated to " + status + " successfully!");
                                    break;
                                case "Subtask2":
                                    task.subtask2.status = status;
                                    alert.success("Subtask 2 updated to " + status + " successfully!");
                                    break;
                                case "Subtask3":
                                    task.subtask3.status = status;
                                    alert.success("Subtask 3 updated to " + status + " successfully!");
                                    break;
                                case "Subtask4":
                                    task.subtask4.status = status;
                                    alert.success("Subtask 4 updated to " + status + " successfully!");
                                    break;
                                default:
                                    alert.error("Please select a valid Subtask checkbox!");
                                    break;
                            }
                        }
                    });
                    await updateDoc(taskDocumentRef, meetData);
                    // setSubtask("");
                    setChecked(!checked);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleModalConfirm = (agendaMeetCode, taskID, subtask,
        status) => {
        //for the Modal Confirm

        setModalState(false);
        updateSubTaskStatus(agendaMeetCode, taskID, subtask,
            status);
    };

    return (
        <GlobalLayout>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {tasks && tasks.length > 0 ? (
                        <>
                            <div className="flex flex-col md:flex-row border-transparent w-fit lg:w-full rounded shadow-lg p-3">
                                <div className="sidebar overflow-y-auto md:overflow-x-auto lg:overflow-x-auto border w-[430px] md:w-1/5 lg:w-1/5">
                                    <div className="side-list h-fit p-2 flex md:flex-col lg:flex-col gap-4">
                                        {/* Your vertical tabs */}
                                        <Tab.Group
                                            defaultIndex={selectedTab}
                                            onChange={(index) => {
                                                setSelectedTab(index);
                                            }}
                                        >
                                            {tasks.map((task, index) => (
                                                <Tab className="" key={index}>
                                                    {({ selected }) => (
                                                        <div
                                                            className={
                                                                "" +
                                                                (selected
                                                                    ? " bg-light-900 text-blue-900 border-2 border-blue-600 bg-sky-100 shadow "
                                                                    : "text-[#252c48] border-2 border-gray-100 hover:bg-white/[2] hover:text-[#252c48]")
                                                            }
                                                        >
                                                            <p className="font-bold m-1">{task.agenda}</p>
                                                        </div>
                                                    )}
                                                </Tab>
                                            ))}
                                        </Tab.Group>
                                    </div>
                                </div>

                                <div className="main w-full md:w-4/5 lg:w-4/5 border-y border-r">
                                    {selectedTab >= 0 && (
                                        <>
                                            <div className="main-title flex justify-between border-b p-2 ">
                                                <p className="text-lg font-bold">
                                                    Task No:{tasks[selectedTab]?.no}
                                                </p>
                                            </div>

                                            <div className="main-body p-2 ">
                                                <div className="tasks">

                                                    <table
                                                        key={tasks[selectedTab]?.no}
                                                        className="table-auto border-collapse border border-blue-900 w-full"
                                                    >
                                                        <thead>
                                                            {tasks[selectedTab]?.subtask1.subtaskName.length >
                                                                0 && (
                                                                    <tr className="bg-blue-100">
                                                                        <th
                                                                            style={{ width: "25%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Subtask Name
                                                                        </th>
                                                                        <th
                                                                            style={{ width: "25%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Supported By
                                                                        </th>
                                                                        <th
                                                                            style={{ width: "25%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Target Date
                                                                        </th>
                                                                        <th
                                                                            style={{ width: "25%" }}
                                                                            className="border border-blue-500 px-4 py-2"
                                                                        >
                                                                            Completed
                                                                        </th>
                                                                    </tr>
                                                                )}
                                                        </thead>
                                                        <tbody>
                                                            {tasks[selectedTab]?.subtask1.subtaskName.length >
                                                                0 &&
                                                                tasks[selectedTab]?.subtask1.supportedBy.label ===
                                                                user ? (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {tasks[selectedTab]?.subtask1.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {
                                                                            tasks[selectedTab]?.subtask1.supportedBy
                                                                                .label
                                                                        }
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            tasks[selectedTab]?.subtask1.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="status"
                                                                            // checked={tasks[selectedTab]?.subtask1.status === "Completed" ? "checked" : ""}
                                                                            checked={checked}
                                                                            id=""
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setChecked(e.target.checked);
                                                                                    setModalState(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {modalState && (
                                                                            <ModalConfirm
                                                                                onClose={() => showHideModalConfirm()}
                                                                                saveData={() => {
                                                                                    const status = checked
                                                                                        ? "Completed"
                                                                                        : "Ongoing";
                                                                                    const subtask = "Subtask1";
                                                                                    handleModalConfirm(
                                                                                        tasks[selectedTab]?.agendaMeetCode,
                                                                                        tasks[selectedTab]?.taskID,
                                                                                        subtask,
                                                                                        status
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <p>
                                                                                    After saving you cannot edit it,
                                                                                    Please Confirm to save....!
                                                                                </p>

                                                                            </ModalConfirm>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {tasks[selectedTab]?.subtask2.subtaskName.length >
                                                                0 &&
                                                                tasks[selectedTab]?.subtask2.supportedBy.label ===
                                                                user ? (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {tasks[selectedTab]?.subtask2.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {
                                                                            tasks[selectedTab]?.subtask2.supportedBy
                                                                                .label
                                                                        }
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            tasks[selectedTab]?.subtask2.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="status"
                                                                            checked={checked}
                                                                            id=""
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setChecked(e.target.checked);
                                                                                    setModalState(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {modalState && (
                                                                            <ModalConfirm
                                                                                onClose={() => showHideModalConfirm()}
                                                                                saveData={() => {
                                                                                    const status = checked
                                                                                        ? "Completed"
                                                                                        : "Ongoing";
                                                                                    const subtask = "Subtask2";
                                                                                    handleModalConfirm(
                                                                                        tasks[selectedTab]?.agendaMeetCode,
                                                                                        tasks[selectedTab]?.taskID,
                                                                                        subtask,
                                                                                        status
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <p>
                                                                                    After saving you cannot edit it,
                                                                                    Please Confirm to save....!
                                                                                </p>
                                                                            </ModalConfirm>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {tasks[selectedTab]?.subtask3.subtaskName.length >
                                                                0 &&
                                                                tasks[selectedTab]?.subtask3.supportedBy.label ===
                                                                user ? (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {tasks[selectedTab]?.subtask3.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {
                                                                            tasks[selectedTab]?.subtask3.supportedBy
                                                                                .label
                                                                        }
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            tasks[selectedTab]?.subtask3.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="status"
                                                                            checked={checked}
                                                                            id=""
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setChecked(e.target.checked);
                                                                                    setModalState(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {modalState && (
                                                                            <ModalConfirm
                                                                                onClose={() => showHideModalConfirm()}
                                                                                saveData={() => {
                                                                                    const status = checked
                                                                                        ? "Completed"
                                                                                        : "Ongoing";
                                                                                    const subtask = "Subtask3";
                                                                                    handleModalConfirm(
                                                                                        tasks[selectedTab]?.agendaMeetCode,
                                                                                        tasks[selectedTab]?.taskID,
                                                                                        subtask,
                                                                                        status
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <p>
                                                                                    After saving you cannot edit it,
                                                                                    Please Confirm to save....!
                                                                                </p>
                                                                            </ModalConfirm>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {tasks[selectedTab]?.subtask4.subtaskName.length >
                                                                0 &&
                                                                tasks[selectedTab]?.subtask4.supportedBy.label ===
                                                                user ? (
                                                                <tr className="hover:bg-blue-50">
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {tasks[selectedTab]?.subtask4.subtaskName}
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {
                                                                            tasks[selectedTab]?.subtask4.supportedBy
                                                                                .label
                                                                        }
                                                                    </td>
                                                                    <td className="border border-blue-500 px-4 py-2">
                                                                        {format(
                                                                            tasks[selectedTab]?.subtask4.targetDate,
                                                                            "dd-MM-yyyy"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="status"
                                                                            checked={checked}
                                                                            id=""
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setChecked(e.target.checked);
                                                                                    setModalState(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {modalState && (
                                                                            <ModalConfirm
                                                                                onClose={() => showHideModalConfirm()}
                                                                                saveData={() => {
                                                                                    const status = checked
                                                                                        ? "Completed"
                                                                                        : "Ongoing";
                                                                                    const subtask = "Subtask4";
                                                                                    handleModalConfirm(
                                                                                        tasks[selectedTab]?.agendaMeetCode,
                                                                                        tasks[selectedTab]?.taskID,
                                                                                        subtask,
                                                                                        status
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <p>
                                                                                    After saving you cannot edit it,
                                                                                    Please Confirm to save....!
                                                                                </p>
                                                                            </ModalConfirm>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="updateBtn flex justify-center">
                                                {/* <button
                                                    type="button"
                                                    className="rounded bg-[#252c48] px-3 py-2 my-3 text-lg font-medium text-white hover:bg-[#252c48ce]"
                                                onClick={() =>
                                                    updateSubTaskStatus(
                                                        tasks[selectedTab]?.agendaMeetCode,
                                                        tasks[selectedTab]?.taskID
                                                    )
                                                }
                                                >
                                                    Update
                                                </button> */}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div>
                            <div className="flex items-center justify-center">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/icons/resting.jpg"}
                                    alt="No tasks"
                                    className="w-[60%] md:w-[30%] lg:w-[30%] h-fit"
                                />
                            </div>
                            <p className="text-center text-2xl font-bold text-gray-600 mt-2">
                                No Subtasks are Ongoing
                            </p>
                        </div>
                    )}
                    <div className="completedSubtasks flex justify-center mt-5">
                        <Link to="/completedSubtasks" className="bg-[#252c48] rounded cursor-pointer p-2 text-white">View Completed Subtasks</Link>
                    </div>
                </>
            )}
        </GlobalLayout>
    );
}

export default SubTasks;
