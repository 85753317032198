export function convertDateFormat(dateString) {
    // Check if input date string matches dd-mm-yyyy or dd/mm/yyyy format
    const match = dateString.match(/^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/);

    if (!match) {
        // If the date format doesn't match, return an error message
        return "Invalid date format. Please use dd-mm-yyyy or dd/mm/yyyy format.";
    }

    // Extract day, month, and year from the matched string
    const separator = dateString.includes("/") ? "/" : "-";
    const [day, month, year] = dateString.split(separator);

    // Create a new date object in mm-dd-yyyy format
    const newDate = new Date(`${month}-${day}-${year}`);

    // Get the month, day, and year in mm-dd-yyyy format
    const newMonth = String(newDate.getMonth() + 1).padStart(2, "0");
    const newDay = String(newDate.getDate()).padStart(2, "0");
    const newYear = newDate.getFullYear();

    // Return the date in mm-dd-yyyy format
    return `${newMonth}${separator}${newDay}${separator}${newYear}`;
}
