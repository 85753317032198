import React, { useState, useEffect } from "react";
import GlobalLayout from "../utils/hoc/globalLayout";
import Dropdown from "../utils/elements/Dropdown/index";
import InputField from "../utils/elements/InputField";
import { Validators } from "../utils/utilities/Validators";
import Button from "../utils/elements/Button/index";
import ModalConfirm from "../utils/elements/modals/ModalConfirm";
import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { query, collection, onSnapshot } from "firebase/firestore";
import ModalInformation from "../utils/elements/modals/ModalInfomation";
import { useAlert } from "react-alert";
import HtmlDropdown from "../utils/elements/HtmlSelect";


const dropDownData = [
  { value: 0, label: "Select Code" },
  { value: 1, label: "Department" },
  { value: 2, label: "Designation" },
];

function NewCodes() {
  const [ddData, setDDData] = useState("Select Code");
  const [codeText, setCodeText] = useState("");
  const [modalState, setModalState] = useState(false);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [plHolder, setPlHolder] = useState("Add");
  const [isModalInformationOpen, setIsModalInformationOpen] = useState(false);
  const [validationError, setValidationError] = useState("");

  const codeMinLength = 5;

  const alert = useAlert();

  const showHideModalInformation = () => {
    if (isModalInformationOpen) {
      setIsModalInformationOpen(false);

      setValidationError("");
    } else {
      setIsModalInformationOpen(true);
    }
  };

  const handleDropdown = (ddData) => {
    setDDData(ddData);
    if (ddData === "Select Code") {
      setPlHolder(`Add`);
    } else {
      setPlHolder(`Add ${ddData.label}`);
    }
  };
  const handleChangeText = (value) => {
    setCodeText(value);
  };

  const validateBeforeSave = () => {
    if (ddData === "Select Code") {
      setValidationError("Validation Error : Code Must Be Selected");

      return true;
    } else if (codeText === "") {
      setValidationError("Validation Error:  Code can't be Blank");

      return true;
    } else if (codeText.length < codeMinLength) {
      setValidationError(
        `Validation Error: Code must be minimum ${codeMinLength} characters`
      );

      return true;
    }
  };

  const showHideModalConfirm = () => {
    if (validateBeforeSave() === true) {
      return;
    } else {
      if (modalState) {
        setModalState(false);
      } else {
        setModalState(true);
      }
    }
  };
  const saveData = () => {
    //for the Modal Confirm

    setModalState(false);
    onSubmit();
  };
  const dept = {
    // codeText: codeText,
  };

  const onLoadMode = () => {
    setDDData("");
    setCodeText("");
  };

  const onSubmit = async () => {
    try {

      if (ddData.label === "Department") {
        await setDoc(doc(db, "Department", codeText), dept);
        alert.success("Department Saved successfully !");
        setCodeText("");
      } else if (ddData.label === "Designation") {
        await setDoc(doc(db, "Designation", codeText), dept);
        alert.success("Designation Saved successfully !");

      }
      onLoadMode();
    } catch (error) {
      alert.error("Error updating profile:", error);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      const fetchedDocIDs = [];
      const q = query(collection(db, "Department"));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedDocIDs.push(doc.id);
        });

        setDepartment(fetchedDocIDs);

      });

      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, [department]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedDocIDs = [];
      const q = query(collection(db, "Designation"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          fetchedDocIDs.push(doc.id);
        });

        setDesignation(fetchedDocIDs);
      });
      return () => {
        unsubscribe();
      };
    };

    fetchData();
  }, [designation]);

  return (
    <GlobalLayout>
      <div className="shadow-md border-1 p-3 rounded mr-3">
        <p className="font-bold text-xl mb-3">Add Codes</p>
        <div className="row gx-3 gy-3">
          <div className="col-sm-3">
            <Dropdown
              data={dropDownData}
              styleClass=""
              value={ddData}
              onChange={handleDropdown}
              placeholder={""}
            />

            {/* <HtmlDropdown
              data={dropDownData}
              onChange={handleDropdown}
              placeholder=""
              value={ddData}
              defaultValue={ddData}
            /> */}
          </div>
          <div className="col-sm-3" >
            <InputField
              id="UserName"
              value={codeText}
              type="text"
              label=""
              placeholder={plHolder}
              validators={[
                { check: Validators.required, message: "Code Is Required" },
              ]}
              onChange={handleChangeText}
            />
          </div>
          <div className="col-sm-2">
            <div >
              <Button
                styleClass="btn-primary"
                onClick={showHideModalConfirm}
                value="Confirm!"
              />
              {modalState && (
                <ModalConfirm
                  onClose={() => showHideModalConfirm()}
                  saveData={() => saveData()}
                >
                  <p>
                    After saving you cannot edit it, Please Confirm to save....!
                  </p>
                </ModalConfirm>
              )}

              {validationError !== "" && (
                <ModalInformation
                  onClose={() => showHideModalInformation()}
                  typeOfModal="Error"
                >
                  <p>{validationError}</p>
                </ModalInformation>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 gy-4 gx-5">
            <div className="col-sm-4 border-1 shadow ps-3">
              <p className="font-bold w-full m-1">Departments</p>
              <hr></hr>
              {department &&
                department.map((dept, index) => (
                  <li className="my-1" key={index}>
                    {dept}
                  </li>
                ))}
            </div>
            <div className="col-sm-1"></div>
            <div className="col-sm-4 border-1 shadow ps-3">
              <p className="font-bold w-full m-1">Designation</p>
              <hr></hr>
              {designation &&
                designation.map((desgn, index) => (
                  <li className="my-1" key={index}>
                    {desgn}
                  </li>
                ))}
            </div>
          </div>
        </div>
      </div>
    </GlobalLayout>
  );
}

export default NewCodes;
