import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validateInput } from "../../utilities/Validators";

const InputField = ({ value, id, label, placeholder, validators
    , type, maxLength, minLength, onChange, rows }) => {

    const [error, setError] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setError(validateInput(validators, value, event));
        onChange(value);
    };

    const handleBlur = (event) => {
        const { value } = event.target;
        if (value === '') {
            setError(validateInput(validators, value));
            onChange(value);
            return;
        }
        if (value.length < minLength) {
            setError({ error: true, message: `${label} Must Be Min  ${minLength}` })
            return;
        }
    };

    return (
        <div className="form-group">
            <h6>{label && <label className='my-2' htmlFor="app-input-field">{label}</label>}</h6>
            {type === 'textarea' ? (
                <textarea
                    id={id}
                    className='form-control'
                    placeholder={placeholder}
                    value={value}
                    rows={rows}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            ) : (
                <input
                    id={id}
                    type={type}
                    value={value}
                    className='form-control'
                    placeholder={placeholder}
                    maxLength={maxLength}
                    minLength={minLength}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            )}
            {error && <span className='text-danger'>{error.message}</span>}
        </div>
    )
};

InputField.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validators: PropTypes.array,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    onChange: PropTypes.func.isRequired
};


InputField.defaultProps = {
    value: '',
    label: 'Default Label',
    placeholder: '',
    type: 'text',
    maxLength: 1000,
    minLength: 0,
    validators: []
};

export default InputField;