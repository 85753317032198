import React, { useState, useEffect } from "react";
import { app, auth, db } from "../../firebase";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import GlobalLayout from "../utils/hoc/globalLayout";
import "./profile.css";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import Dropdown from "../utils/elements/Dropdown/index";
import { query, collection, onSnapshot } from "firebase/firestore";
import Checkbox from "../utils/elements/Checkbox/index";



const NewProfile = () => {

  const [deptData, setDeptData] = useState("Select Department");
  const [desgnData, setDesgnData] = useState("Select Designation");

  // created with new array
  const [newDeptData, setNewDeptData] = useState([]);
  const [newDesignationData, setNewDesignationData] = useState([]);
  // ***********
  const [isManager, setIsManager] = useState(false);
  const [errorD, setErrorD] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const alert2 = useAlert();

  const handleDropdownDepartment = (ddData) => {
    setDeptData(ddData);
  };

  const handleDropdownDesignation = (ddData) => {
    setDesgnData(ddData);
  };

  const handleCheckbox = (checkState) => {
    setIsManager(checkState);
  };

  const onSubmit = async (data) => {
    if (deptData === "Select Department") {
      return alert("Select Department");
    }
    if (desgnData === "Select Designation") {
      return alert("Select Designation");
    }

    try {
      const { email, password, name } = data;
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Set user details in Firebase Authentication and wait for completion
      await updateProfile(user, {
        displayName: name,
        phoneNumber: data.phone,
        photoURL: "/images/penguin.png",
      });

      // Construct user details JSON
      let inputJson = {
        employeeID: data.employeeID,
        name: data.name,
        about: data.about,
        department: deptData,
        designation: desgnData,
        email: data.email,
        phone: data.phone,
        isManager: isManager,
      };

      // Get Firestore instance and wait for user document to be set
      const db = getFirestore(app);
      await setDoc(doc(db, "Users", email), inputJson);

      alert2.success("Profile saved successfully!");

      // Optionally, log out the user here if necessary
      // await signOut(auth);

      // Handle any redirection or UI updates here
    } catch (error) {
      alert2.error(`${error.code}: ${error.message}`);
    }
  };



  const createNewArray = (existingArray, arrayName) => {
    if (arrayName === "Department") {
      let newDeptDataVariable = existingArray.map((obj, index) => ({
        value: index + 1,
        label: obj,
      }));
      newDeptDataVariable.unshift({
        value: 0,
        label: "Select Department",
      });

      setNewDeptData(newDeptDataVariable);
    }
    if (arrayName === "Designation") {
      let newDesgnDataVariable = existingArray.map((obj, index) => ({
        value: index + 1,
        label: obj,
      }));
      newDesgnDataVariable.unshift({
        value: 0,
        label: "Select Designation",
      });

      setNewDesignationData(newDesgnDataVariable);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "Department"));
      let departmentData = []; // Clear departmentData array

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          departmentData.push(doc.id);
        });
        createNewArray(departmentData, "Department");
      });

      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "Designation"));
      let designationData = []; // Clear designationData array

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          designationData.push(doc.id);
        });

        createNewArray(designationData, "Designation");
      });

      return () => {
        unsubscribe();
      };
    };
    fetchData();
  }, []);


  return (
    <GlobalLayout>
      <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-center font-bold text-2xl">Create New Profile</h1>
        <div className=" formNewProfile max-w-6xl mx-auto mt-5 p-4 bg-white shadow-lg rounded-md">
          <div className="row">
            <div className="col-sm-2">
              {" "}
              <label className="block w-full  font-semibold text-gray-600">
                Employee ID:
              </label>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                className="w-full py-2 px-3 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                {...register("employeeID", {
                  required: "Employee ID is Required",
                })}
              />

              <p className="text-red-500 font-semibold ">
                {errors.employeeID?.message && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {errors.employeeID?.message}
                  </span>
                )}
              </p>
            </div>

            <div className="col-sm-2">
              {" "}
              <label className="block font-semibold text-gray-600">
                Name:
              </label>
            </div>
            <div className="col-sm-4">
              {" "}
              <input
                type="text"
                className="w-full py-2 px-3 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                {...register("name", {
                  required: "Name is Required",
                  minLength: {
                    value: 4,
                    message: "Minimum 4 characters required.",
                  },
                })}
              />
              <p className="text-red-500 font-semibold ">
                {errors.name?.message && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {errors.name?.message}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2">
              <label className="block font-semibold text-gray-600">
                Department:
              </label>
            </div>
            <div className="col-sm-4">
              <Dropdown
                data={newDeptData}
                styleClass="my-2"
                value={deptData}
                onChange={handleDropdownDepartment}
                placeholder={""}
              />

              <p className="text-red-500 font-semibold ">
                {errorD && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i> {errorD}
                  </span>
                )}
              </p>
            </div>
            <div className="col-sm-2">
              <label className="block font-semibold text-gray-600">
                Designation:
              </label>
            </div>
            <div className="col-sm-4">
              {" "}
              <Dropdown
                data={newDesignationData}
                styleClass=""
                value={desgnData}
                onChange={handleDropdownDesignation}
                placeholder={""}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-2">
              {" "}
              <label className="block font-semibold text-gray-600">
                Email:
              </label>
            </div>
            <div className="col-sm-4">
              {" "}
              <input
                type="text"
                className="w-full py-2 px-3 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                {...register("email", {
                  required: "Email address is Required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+(?<!\.\.)@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
                    message: "Invalid email address"
                  },

                })}
              />
              <p className="text-red-500 font-semibold ">
                {errors.email?.message && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {errors.email?.message}
                  </span>
                )}
              </p>
            </div>
            <div className="col-sm-2">
              <label className="block font-semibold text-gray-600">
                Password:
              </label>
            </div>
            <div className="col-sm-4">
              <input
                type="password"
                className="w-full py-2 px-3 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                {...register("password", {
                  required: "Password is Required",
                  minLength: {
                    value: 4,
                    message: "Minimum 4 characters required.",
                  },
                })}
              />
              <p className="text-red-500 font-semibold ">
                {errors.password?.message && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {errors.password?.message}
                  </span>
                )}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-2">
              {" "}
              <label className="block font-semibold text-gray-600">
                Phone:
              </label>
            </div>
            <div className="col-sm-4">
              {" "}
              <input
                type="text"
                className="w-full py-2 px-3 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                {...register("phone", {
                  required: "Phone number required",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Invalid phone number format",
                  },
                })}
              />
              <p className="text-red-500 font-semibold">
                {errors.phone?.message && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {errors.phone?.message}
                  </span>
                )}
              </p>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-4">
              <div className="m-3">
                <Checkbox
                  label="Is Manager"
                  selected={isManager}
                  onChange={handleCheckbox}
                />
                <p className="text-red-500 font-semibold">
                  {errorD && (
                    <span>
                      <i className="bi bi-exclamation-circle mr-2"></i> {errorD}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2"> <label className="block font-semibold text-gray-600">
              About:
            </label></div>
            <div className="col-sm-10"> <textarea
              className="w-full py-2 px-3 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
              {...register("about", {
                required: "About is required",
                minLength: {
                  value: 10,
                  message: "Minimum 10 characters are required.",
                },
              })}
            />
              <p className="text-red-500 font-semibold ">
                {errors.about?.message && (
                  <span>
                    <i className="bi bi-exclamation-circle mr-2"></i>{" "}
                    {errors.about?.message}
                  </span>
                )}
              </p></div>
          </div>


          <div className="flex justify-center">
            <input
              type="submit"
              value="Submit"
              className="py-2 px-3 rounded bg-[#252c48] font-medium text-white hover:bg-[#3a4675]"
            />
          </div>
        </div>
      </form>
    </GlobalLayout>
  );
};

export default NewProfile;
