import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { fetchMeeting } from "../../utils/utilities/fetchMeeting";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { convertDateFormat } from "../../utils/utilities/utils.js";
import './Taskpiechart.css'

export const options = {
  pieSliceText: "label",
  width: 400,
  height: 300,
  pieStartAngle: 100,
  legend: { position: "none" },
  animation: {
    startup: true,
    easing: "linear",
    duration: 1000,
  },
};

const TaskPieChart = () => {
  const [allTasks, setAllTasks] = useState();
  var counterOpen = 0;
  var counterOverdue = 0;
  var counterCompleted = 0;
  const firebaseTimestamp = firebase.firestore.Timestamp.now().toDate();

  // Manipulating the timestamp
  const timestamp = new Date(firebaseTimestamp);
  timestamp.setHours(0);
  timestamp.setMinutes(0);
  timestamp.setSeconds(0);
  timestamp.setMilliseconds(0);


  useEffect(() => {
    (async () => {
      setAllTasks(await fetchMeeting());
    })();
  }, []);

  if (allTasks) {
    allTasks.forEach((task) => {
      const newTargetDate = new Date(convertDateFormat(task.targetDate));
      if (task.status === "Ongoing" && newTargetDate >= timestamp) {
        counterOpen = counterOpen + 1;
      }
    });
    allTasks.forEach((task) => {
      const newTargetDate = new Date(convertDateFormat(task.targetDate));
      if (task.status !== "Completed" && newTargetDate < timestamp) {

        counterOverdue = counterOverdue + 1;
      }
    });
    allTasks.forEach((task) => {
      if (task.status === "Completed") {
        counterCompleted = counterCompleted + 1;
      }
    });
  }
  const data = [
    ["Status", "Tasks Status in Percentage"],
    ["Not Started", 0],
    ["Overdue", counterOverdue],
    ["On Going", counterOpen],
    ["Completed", counterCompleted],
  ];
  return (

    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      // width={"100%"}
      // height={"100%"}
      className="-ml-[20%] md:ml-0 lg:ml-0"
    />

  );
};

export default TaskPieChart;
