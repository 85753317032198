import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import "firebase/auth";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import {
  getStorage,
  ref as sRef,
  uploadBytes,
  getDownloadURL,
  updateMetadata,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDyNnwo-6iRjJ7hWSJj8Llp0TpwqrCT-Ng",
  authDomain: "meeting-minutes-papl.firebaseapp.com",
  projectId: "meeting-minutes-papl",
  storageBucket: "meeting-minutes-papl.appspot.com",
  messagingSenderId: "243874885679",
  appId: "1:243874885679:web:ca14c4656ab683cec2d6da",
  measurementId: "G-M6ZYTE6LVL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage();
const db = getFirestore(app);

// Set persistence to browser session
export function useAuth() {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) =>
      setCurrentUser(user)
    );
    return unsubscribe;
  }, []);

  return currentUser;
}

// Set persistence to browser session
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log("Authentication persistence set");
  })
  .catch((error) => {
    console.error("Error setting authentication persistence:", error);
  });

export async function upload(file, currentUser, setLoading) {
  const fileRef = sRef(storage, "ProfilePics/" + currentUser.uid + ".png");

  setLoading(true);

  try {
    // Set the content type to 'image/png'
    const metadata = {
      contentType: "image/.*",
    };

    // Update the metadata before uploading
    await updateMetadata(fileRef, metadata);

    // Upload the file
    await uploadBytes(fileRef, file);

    // Get the download URL
    const photoURL = await getDownloadURL(fileRef);

    // Update the user's profile with the new photoURL
    updateProfile(currentUser, { photoURL });

    setLoading(false);
    alert("Uploaded..!!!!");
    var profileFetched = true;
    return photoURL && profileFetched;
  } catch (error) {
    console.error("Currently Updating Profile Photo is not possible.", error);
    setLoading(false);
    alert("Currently Updating Profile Photo is not Available.");
    return null;
  }
}

export { auth, db, storage, app, onAuthStateChanged, updateProfile };
