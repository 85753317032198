import React, { useState, Fragment, useEffect, useMemo } from "react";
import GlobalLayout from "../../utils/hoc/globalLayout.js";
import DatePicker from "react-datepicker";
import { Tab } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";
import { auth, db } from "../../../firebase.js";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import {
    doc,
    getDoc,
    collection,
    getDocs,
    updateDoc,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { convertDateFormat } from "../../utils/utilities/utils.js";
import LoadingSpinner from "../../utils/elements/LoadingSpinner.js";
import ModalConfirm from "../../utils/elements/modals/ModalConfirm.js";
import InputField from "../../utils/elements/InputField/index.js";


const OverdueTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("Ongoing");
    const [modalState2, setModalState2] = useState(false);
    const [completionComment, setCompletionComment] = useState("");

    const currentUser = auth.currentUser;
    const user = currentUser.displayName;
    const alert = useAlert();
    const firebaseTimestamp = firebase.firestore.Timestamp.now().toDate();



    const timestamp = useMemo(() => {
        const newTimestamp = new Date(firebaseTimestamp);
        newTimestamp.setHours(0);
        newTimestamp.setMinutes(0);
        newTimestamp.setSeconds(0);
        newTimestamp.setMilliseconds(0);
        return newTimestamp;
    }, [firebaseTimestamp]);

    useEffect(() => {
        const fetchMeetingData = async () => {
            try {
                const meetingsCollectionRef = collection(db, "Meetings");
                const meetingsSnapshot = await getDocs(meetingsCollectionRef);
                const ids = meetingsSnapshot.docs.map((doc) => doc.id);

                const data = [];
                for (const meetingDoc of ids) {
                    const meetCollectionRef = collection(
                        db,
                        "Meetings",
                        meetingDoc,
                        "Meet",
                    );
                    const meetSnapshot = await getDocs(meetCollectionRef);
                    const meetData = meetSnapshot.docs.map((doc) => ({ ...doc.data() }));
                    data.push(...meetData);
                }
                const allTasks = data.reduce(
                    (allTasks, meeting) => allTasks.concat(meeting.tasks),
                    [],
                );

                setTasks(
                    allTasks.filter((task) => {
                        const newTargetDate = new Date(convertDateFormat(task.targetDate));
                        return (
                            (task.actionBy === user || task.actionBy === user) &&
                            // isBefore(newTargetDate, timestamp) &&
                            newTargetDate < timestamp &&
                            task.status !== "Completed"
                            //  && (task.status && task.status !== "Ongoing")
                        );
                    }),
                );
                setLoading(false);
            } catch (error) {
                console.error("Error fetching meeting data:", error);
            }
        };

        fetchMeetingData();
    }, [timestamp, user]);

    const showHideAlert = (state) => {
        setModalState2(() => !state);
    };

    const changeComment = (data1) => {
        setCompletionComment(data1);
    };

    const updateTasks = async (agendaMeetCode, taskId) => {
        setModalState2(() => false);
        try {
            const meetingsCollectionRef = collection(db, "Meetings");
            const meetingsSnapshot = await getDocs(meetingsCollectionRef);
            const ids = meetingsSnapshot.docs.map((doc) => doc.id);

            for (const meetingIds of ids) {
                const taskDocumentRef = doc(
                    db,
                    "Meetings",
                    meetingIds,
                    "Meet",
                    agendaMeetCode,
                );
                const taskSnapshot = await getDoc(taskDocumentRef);
                const meetData = taskSnapshot.data();

                if (meetData) {
                    meetData.tasks.forEach((task) => {
                        if (task.taskID === taskId) {
                            if (task.subtask1.subtaskName.length > 1) {
                                console.log("came in ");
                                if (
                                    task.subtask1.status === "Completed" ||
                                    task.subtask2.status === "Completed" ||
                                    task.subtask3.status === "Completed" ||
                                    task.subtask4.status === "Completed"
                                ) {
                                    task.status = status;
                                    task.comment = completionComment;
                                    updateDoc(taskDocumentRef, meetData);
                                    alert.success(
                                        "Task updated successfully to " + status + " !",
                                    );
                                } else {
                                    alert.error("Please complete the Subtasks !!");
                                }

                                // task.subtask1.status = "Completed";
                            } else {
                                alert.error("There are no subtasks!");
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <GlobalLayout>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {tasks && tasks.length > 0 ? (
                        <>
                            <div className="w-[99%] bg-red-500 p-1 text-center text-base text-white">
                                <p>These Tasks are overdue. Please complete them ASAP! ⚠️🚨</p>
                            </div>

                            <div className="mr-3 flex w-fit  flex-col rounded border-transparent p-1 shadow-lg md:flex-row lg:w-full">
                                <div className="sidebar w-[430px] overflow-y-auto border md:w-1/5 md:overflow-x-auto lg:w-1/5 lg:overflow-x-auto">
                                    <div className="side-list flex h-fit gap-4 p-2 md:flex-col lg:flex-col">
                                        {/* Your vertical tabs */}
                                        <Tab.Group
                                            defaultIndex={selectedTab}
                                            onChange={(index) => setSelectedTab(index)}
                                        >
                                            {tasks.map((task, index) => (
                                                <Tab className="" key={index}>
                                                    {({ selected }) => (
                                                        <div
                                                            className={
                                                                "" +
                                                                (selected
                                                                    ? " bg-light-900 border-2 border-blue-600 bg-sky-100 text-blue-900 shadow "
                                                                    : "border-2 border-gray-100 text-[#252c48]  hover:bg-white/[2] hover:text-[#252c48]")
                                                            }
                                                        >
                                                            <p className="m-1 font-bold">{task.agenda}</p>
                                                            <ul></ul>
                                                        </div>
                                                    )}
                                                </Tab>
                                            ))}
                                        </Tab.Group>
                                    </div>
                                </div>

                                <div className="main w-full border-y border-r md:w-4/5 lg:w-4/5">
                                    {selectedTab >= 0 && (
                                        <>
                                            <div className="main-title flex justify-between border-b p-2 ">
                                                <p className="text-lg font-bold">
                                                    Task ID: {tasks[selectedTab]?.taskUID}
                                                </p>
                                            </div>

                                            <div className="main-body p-2 ">
                                                <div className="tasks">
                                                    <div className="first-strip flex  items-center justify-between border p-2 px-4 shadow-sm ">
                                                        <div className="dropdown">
                                                            <select
                                                                id="status"
                                                                name="status"
                                                                onChange={(e) => setStatus(e.target.value)}
                                                                className="border-transparency h-10 w-fit rounded bg-white px-2 py-0 tracking-wider text-black focus:border-black focus:outline-none md:px-3 md:py-1"
                                                            >
                                                                <option
                                                                    className="border-red-500 bg-red-50"
                                                                    value="Overdue"
                                                                >
                                                                    Overdue
                                                                </option>
                                                                <option value="Completed">Completed</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="second-strip mt-3 flex items-center border p-2 px-4 shadow-sm">
                                                        <p className="font-bold">Description:</p>{" "}
                                                        &nbsp;&nbsp;
                                                        <p>{tasks[selectedTab]?.description}</p>
                                                    </div>

                                                    <div className="third-strip first-letter: mt-3 flex flex-col gap-2.5 border p-2 px-4 shadow-sm">
                                                        <p className="font-bold ">Task Information</p>
                                                        <div className="flex flex-col justify-between md:flex-row lg:flex-row">
                                                            <p>
                                                                Action By : &nbsp;&nbsp;&nbsp;
                                                                {tasks[selectedTab]?.actionBy && (
                                                                    <span className="border-1 m-1 border-blue-600 bg-blue-100 p-2 text-base font-semibold shadow-sm shadow-blue-700">
                                                                        {tasks[selectedTab]?.actionBy}
                                                                    </span>
                                                                )}
                                                            </p>
                                                            <p className="mt-4 lg:mt-0">
                                                                Action For : &nbsp;&nbsp;&nbsp;
                                                                {tasks[selectedTab]?.actionFor && (
                                                                    <span className="border-1 m-1 border-pink-600 bg-pink-100 p-2 text-base font-semibold shadow-sm shadow-pink-700">
                                                                        {tasks[selectedTab]?.actionFor}
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </div>

                                                        <div className="flex flex-col gap-3 md:flex-row lg:flex-row lg:items-center">
                                                            <p>Start Date : </p>
                                                            <DatePicker
                                                                showIcon
                                                                dateFormat="dd/MM/yyyy"
                                                                value={tasks[selectedTab]?.startDate}
                                                                selectsStart
                                                                readOnly
                                                                icon="bi bi-calendar-date-fill"
                                                            />

                                                            <p className="lg:ml-auto">Due Date : </p>
                                                            <div className=" lg:mr-auto">
                                                                <DatePicker
                                                                    showIcon
                                                                    dateFormat="dd/MM/yyyy"
                                                                    value={tasks[selectedTab]?.targetDate}
                                                                    selectsEnd
                                                                    readOnly
                                                                    icon="bi bi-calendar-date-fill"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fourth-strip mt-4 ">
                                                        <Tab.Group>
                                                            <Tab.List className="bg-light-900 flex  space-x-1 rounded-xl p-1">
                                                                <Tab
                                                                    as={Fragment}
                                                                    className="w-1/6 py-2.5 text-sm font-medium leading-5 "
                                                                >
                                                                    {({ selected }) => (
                                                                        /* Use the `selected` state to conditionally style the selected tab. */
                                                                        <button
                                                                            className={
                                                                                selected
                                                                                    ? "bg-light-900 border-x-2 border-b-2 text-blue-900 shadow-sm "
                                                                                    : "text-[#252c48]  hover:bg-white/[2] hover:text-[#252c48]"
                                                                            }
                                                                        >
                                                                            Subtasks
                                                                        </button>
                                                                    )}
                                                                </Tab>
                                                            </Tab.List>
                                                            <Tab.Panels className="m-1 border shadow ">
                                                                <Tab.Panel className="p-3">
                                                                    <div className="m-3">
                                                                        <table
                                                                            key={tasks[selectedTab]?.no}
                                                                            className="table-auto border-collapse border border-blue-900"
                                                                        >
                                                                            <thead>
                                                                                {tasks[selectedTab]?.subtask1
                                                                                    .subtaskName.length > 0 && (
                                                                                        <tr className="bg-blue-100">
                                                                                            <th
                                                                                                style={{ width: "40%" }}
                                                                                                className="border border-blue-500 px-4 py-2"
                                                                                            >
                                                                                                Subtask Name
                                                                                            </th>
                                                                                            <th
                                                                                                style={{ width: "35%" }}
                                                                                                className="border border-blue-500 px-4 py-2"
                                                                                            >
                                                                                                Supported By
                                                                                            </th>
                                                                                            <th
                                                                                                style={{ width: "45%" }}
                                                                                                className="border border-blue-500 px-4 py-2"
                                                                                            >
                                                                                                Target Date
                                                                                            </th>
                                                                                            <th
                                                                                                style={{ width: "45%" }}
                                                                                                className="border border-blue-500 px-4 py-2"
                                                                                            >
                                                                                                Status
                                                                                            </th>
                                                                                        </tr>
                                                                                    )}
                                                                            </thead>
                                                                            <tbody>
                                                                                {tasks[selectedTab]?.subtask1
                                                                                    .subtaskName.length > 0 && (
                                                                                        <tr className="hover:bg-blue-50">
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask1
                                                                                                        .subtaskName
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask1
                                                                                                        .supportedBy.label
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {format(
                                                                                                    tasks[selectedTab]?.subtask1
                                                                                                        .targetDate,
                                                                                                    "dd-MM-yyyy",
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask1
                                                                                                        ?.status
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                {tasks[selectedTab]?.subtask2
                                                                                    .subtaskName.length > 0 && (
                                                                                        <tr className="hover:bg-blue-50">
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask2
                                                                                                        .subtaskName
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask2
                                                                                                        .supportedBy.label
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {format(
                                                                                                    tasks[selectedTab]?.subtask2
                                                                                                        .targetDate,
                                                                                                    "dd-MM-yyyy",
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask2
                                                                                                        ?.status
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                {tasks[selectedTab]?.subtask3
                                                                                    .subtaskName.length > 0 && (
                                                                                        <tr className="hover:bg-blue-50">
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask3
                                                                                                        .subtaskName
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask3
                                                                                                        .supportedBy.label
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {format(
                                                                                                    tasks[selectedTab]?.subtask3
                                                                                                        .targetDate,
                                                                                                    "dd-MM-yyyy",
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask3
                                                                                                        ?.status
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                {tasks[selectedTab]?.subtask4
                                                                                    .subtaskName.length > 0 && (
                                                                                        <tr className="hover:bg-blue-50">
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask4
                                                                                                        .subtaskName
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask4
                                                                                                        .supportedBy.label
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {format(
                                                                                                    tasks[selectedTab]?.subtask4
                                                                                                        .targetDate,
                                                                                                    "dd-MM-yyyy",
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="border border-blue-500 px-4 py-2">
                                                                                                {
                                                                                                    tasks[selectedTab]?.subtask4
                                                                                                        ?.status
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Tab.Panel>
                                                            </Tab.Panels>
                                                        </Tab.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="updateBtn flex justify-center">
                                                <button
                                                    type="button"
                                                    className="my-3 rounded bg-[#252c48] px-3 py-2 text-lg font-medium text-white hover:bg-[#252c48ce]"
                                                    onClick={() => showHideAlert(modalState2)}
                                                >
                                                    Update
                                                </button>
                                                {modalState2 && (
                                                    <ModalConfirm
                                                        onClose={() => showHideAlert(modalState2)}
                                                        saveData={() =>
                                                            updateTasks(
                                                                tasks[selectedTab]?.agendaMeetCode,
                                                                tasks[selectedTab]?.taskID,
                                                            )
                                                        }
                                                    >
                                                        <p className=" mb-4 mt-2">
                                                            Are you confirm to save....!
                                                        </p>

                                                        <InputField
                                                            type={"text"}
                                                            id="outlined-multiline-static"
                                                            onChange={changeComment}
                                                            value={completionComment || ""}
                                                            rows={4}
                                                            label={"Task Completion Comment:"}
                                                            placeholder="Type your comment here:"
                                                        />
                                                    </ModalConfirm>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div>
                            <div className="flex items-center justify-center">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + "/images/icons/overduetasks.png"
                                    }
                                    alt="No tasks"
                                    className="h-fit w-[30%]"
                                />
                            </div>
                            <p className="mt-2 text-center text-2xl font-bold text-gray-600">
                                No tasks are overdue
                            </p>
                        </div>
                    )}
                </>
            )}
        </GlobalLayout>
    );
};

export default OverdueTasks;
