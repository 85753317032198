import React from "react";
import Header from "../../header/Header";
import Navbar from "../../navbar/Navbar";

const GlobalLayout = ({ children }) => {
  return (


    <div className="flex h-screen">
      <nav className="overflow-auto w-auto flex-shrink-0 bg-[#252c48]">
        <Navbar />
      </nav>
      <div className="flex  flex-col">
        <Header />
        <div className="flex-grow mt-3 ms-2 overflow-y-auto">{children}</div>
      </div>
    </div>

  );
};

export default GlobalLayout;
