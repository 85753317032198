import React, { useState } from "react";
import PropTypes from "prop-types";

import "./select.css";
const HtmlDropdown = ({ value, data, placeholder, onChange, defaultValue, margin }) => {
    const [error, setError] = useState("");
    const [selectedValue, setSelectedValue] = useState();

    const handleBlur = (value) => {
        if (selectedValue > 0) {
        } else {
            if (value.value === undefined || value.value === 0) {
                setError(`Must ${data[0].label} `);
            } else {
                setError("");
            }
        }
    };

    const handleSelectionChanged = (e) => {
        let v = e.target.value;

        setSelectedValue(data.find((item) => item.value === +v));
        onChange(data.find((item) => item.value === +v));

        setSelectedValue(value.value);
        if (value.value > 0) {
            setError("");
        } else {
            setError(`Must ${data[0].label} `);
        }
    };

    return (
        <div className="w-full">
            <h6>{placeholder && <label>{placeholder}</label>}</h6>

            <select
                value={defaultValue || ""} // if defaultValue is undefined, use ""
                className="select w-full "
                style={{ padding: "5px", margin: margin }} // set the margin using the prop
                onChange={handleSelectionChanged} // pass the event to the handler
                onBlur={handleBlur} // pass the event to the handler
            >
                {data.map((item) => ( // map over data, using item.value as the key
                    <option key={item.value} value={item.value}>
                        {item.label} {/* render item.label */}
                    </option>
                ))}
            </select>
        </div >
    );
};

HtmlDropdown.propTypes = {
    value: PropTypes.number,
    placeholder: PropTypes.string,
    data: PropTypes.array.isRequired,
    styleClass: PropTypes.string,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    margin: PropTypes.string,

    // ref: PropTypes.string,
};

HtmlDropdown.defaultProps = {
    value: 0,
    defaultValue: 0,
    styleClass: "",
    placeholder: "Select",
    margin: "2%"
    // ref: 'ref'
};

export default HtmlDropdown;

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import './select.css';

// const HtmlDropdown = ({ value, data, placeholder, styleClass, onChange, defaultValue }) => {
//     const [error, setError] = useState('');
//     const [selectedValue, setSelectedValue] = useState(defaultValue);

//     useEffect(() => {
//         setSelectedValue(value);
//     }, [value]);

//     const handleChange = (e) => {
//         const selectedValue = parseInt(e.target.value);
//         setSelectedValue(selectedValue);
//         onChange(selectedValue);
//         setError(selectedValue > 0 ? '' : `Must select ${data[0].label}`);
//     };

//     const handleBlur = () => {
//         if (selectedValue <= 0) {
//             setError(`Must select ${data[0].label}`);
//         } else {
//             setError('');
//         }
//     };

//     return (
//         <div className={`form-group ${styleClass}`}>
//             <h6>{placeholder && <label>{placeholder}</label>}</h6>
//             <select
//                 value={selectedValue}
//                 className='select'
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//             >
//                 {data.map((item, index) => (
//                     <option key={index} className='option' value={item.value}>{item.label}</option>
//                 ))}
//             </select>
//             {error && <span className="error">{error}</span>}
//         </div>
//     );
// };

// HtmlDropdown.propTypes = {
//     value: PropTypes.number.isRequired,
//     placeholder: PropTypes.string,
//     data: PropTypes.arrayOf(PropTypes.object).isRequired,
//     styleClass: PropTypes.string,
//     defaultValue: PropTypes.number,
//     onChange: PropTypes.func.isRequired
// };

// HtmlDropdown.defaultProps = {
//     placeholder: 'Select',
//     styleClass: '',
//     defaultValue: 0
// };

// export default HtmlDropdown;
