import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';


const Dropdown = ({ value, data, placeholder, styleClass, onChange }) => {

    const [error, setError] = useState('');
    const [selectedValue, setSelectedValue] = useState(0)

    const handleChange = (value) => {
        onChange(value);
        setSelectedValue(value.value)
        if (value.value > 0) {
            setError('');
        } else {
            setError(`Must ${data[0].label} `);
        }
        // { console.log('onChange', value) }
    };

    const handleBlur = (value) => {
        // { console.log('handleBlur ', value) }
        if (selectedValue > 0) {
        } else {
            if (value.value === undefined || value.value === 0) {
                setError(`Must ${data[0].label} `);
                // setError('Must Select A Value');
            } else {
                setError('');
            }
        }

    };

    return (
        <div className={` ${styleClass}`}>
            <h6>{placeholder && <label>{placeholder}</label>}</h6>
            <Select
                defaultValue={data[0]}
                value={value}
                // className="form-control"
                options={data}
                getOptionValue={option => `${option}`}
                onChange={handleChange}
                onBlur={handleBlur}
                // isClearable={true}
                placeholder={placeholder}
                textFieldProps={{
                    label: placeholder,
                    InputLabelProps: {
                        shrink: true,
                    },
                }}

            >
            </Select>
            {/* {console.log('error', error)} */}
            {error && <span className='text-danger'>{error}</span>}
        </div>
    )
};

Dropdown.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    data: PropTypes.array.isRequired,
    styleClass: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

Dropdown.defaultProps = {
    value: '',
    styleClass: '',
    placeholder: 'Select'
};

export default Dropdown;